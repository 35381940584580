import React from 'react';
import { Form, Input, Button, Space, FormInstance } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface AddPropProps{
    form: FormInstance
}

const AddProp: React.FC<AddPropProps> = ({form}) => {

    const onFinish = (values) => {
        console.log('Received values:', values);
        // 在这里处理表单数据
    };

    return (
        <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
            <Form.Item name="name" label="属性名称">
                <Input placeholder="请输入属性名称" />
            </Form.Item>

            <Form.List name="attributes">
                {(fields, { add, remove }) => (
                    <div>
                        {fields.map(({ key, name, ...restField }) => (
                            <div key={key} style={{ display: 'flex', marginBottom: 8, border: "solid 1px #e9e9e9", padding: "10px", width: "630px" }}>
                                <Form.Item
                                    style={{ width: "50%" }}
                                    {...restField}
                                    name={[name, 'name']}
                                    rules={[{ required: true, message: 'Missing attribute name' }]}
                                >
                                    <Input placeholder="产品属性名称" />
                                </Form.Item>
                                <Form.List name={[name, 'values']}>
                                    {(valueFields, { add: addValue, remove: removeValue }) => (
                                        <div style={{ width: "50%" }}>
                                            {valueFields.map(({ key: valueKey, name: valueName, ...valueRestField }) => (
                                                <Space key={valueKey} align="baseline">
                                                    <Form.Item
                                                        {...valueRestField}
                                                        name={[valueName, 'value']}
                                                        rules={[{ required: true, message: '请填写产品属性值' }]}
                                                    >
                                                        <Input placeholder="产品属性值" />
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => removeValue(valueName)} />
                                                </Space>
                                            ))}
                                            <Button style={{width:"60%"}} type="dashed" onClick={() => addValue()} block icon={<PlusOutlined />}>
                                                添加产品属性值
                                            </Button>
                                        </div>
                                    )}
                                </Form.List>
                                <MinusCircleOutlined style={{color:"red"}} onClick={() => remove(name)} />
                            </div>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加产品属性名称
                            </Button>
                        </Form.Item>
                    </div>
                )}
            </Form.List>
        </Form>
    );
};

export default AddProp;