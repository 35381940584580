import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { Form, Input, Select, Radio, Button, Space, message, InputNumber,Image, DatePicker } from 'antd'
import { useNavigate,useSearchParams   } from 'react-router-dom';
const queryParams = new URLSearchParams(location.search);
import "./memberEdit.scss"; //引入样式
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";

const { TextArea } = Input;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const MemberAdd: React.FC = () => {
    const navigate  = useNavigate ();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [form] = Form.useForm();
    const [record, setRecord] = useState<any>({})



    useEffect(()=>{
        if (id) {
            console.log("userid:",id)
            axiosInstance.post(api.getUserById, { id })
                .then(res => {
                    console.log(res.data.main)
                    if ((res as any).code === 0) {
                        setRecord(res.data)
                        // form.setFieldsValue(res.data)
                        form.setFieldValue("be_member_time", dayjs(res.data.be_member_time, "YYYY-MM-DD"));
                        form.setFieldValue("company_img", res.data.company_img);
                        form.setFieldValue("company_name", res.data.company_name);
                        form.setFieldValue("email", res.data.email);
                        form.setFieldValue("is_active", res.data.is_active);
                        form.setFieldValue("main", res.data.main);
                        form.setFieldValue("score", res.data.score);
                        form.setFieldValue("user_level", res.data.user_level);
                        form.setFieldValue("user_nickname", res.data.user_nickname);
                        form.setFieldValue("user_phone", res.data.user_phone);
                        form.setFieldValue("user_password","");
                    }
                })
        }
    },[id])

    // 自定义确认密码校验规则
    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('user_password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('两次密码不相同'));
        },
    });

    // 保存
    const onSave = () => {
        form.validateFields()
            .then(
                (values) => {
                    values.be_member_time = values?.be_member_time?.format("YYYY-MM-DD");
                    values.id = id;
                    axiosInstance.post(api.updateUser, values)
                    .then(res=>{
                        if((res as any).code===0){
                            message.success("保存成功");
                            navigate(-1);
                        }
                    })

                }

            )
            .catch(err => { })
    }

    // 取消
    const onCancel = () => {

    }

    const memberOptions = [
        {
            label: "注册经销",
            value: 1
        },
        {
            label: "授权经销",
            value: 2
        },
        {
            label: "VIP经销",
            value: 3
        }
    ]


    return (
        <div className="member-add">
            <div className="form">
                <Form
                    {...layout}
                    layout={"horizontal"}
                    style={{ maxWidth: 600 }}
                    form={form}
                    labelAlign="right"
                >
                    <Form.Item label="登录名(手机)" name="user_phone" rules={[{ required: true, message: '请填写手机号', pattern: /^(?:(?:\+|00)86)?1\d{10}$/ }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="密码" name="user_password" >
                        <Input.Password></Input.Password>
                    </Form.Item>
                    <Form.Item label="确认密码" name="confirm_pwd" rules={[validateConfirmPassword]}>
                        <Input.Password></Input.Password>
                    </Form.Item>
                    <Form.Item label="会员等级" name="user_level">
                        <Select options={memberOptions }></Select>
                    </Form.Item>
                    <Form.Item label="会员注册时间" name="be_member_time">
                        <DatePicker/>
                    </Form.Item>
                    <Form.Item label="会员积分" name="score">
                        <InputNumber min={0} style={{width:"100%"}}></InputNumber>
                    </Form.Item>
                    <Form.Item label="是否为主账号" name="main">
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="是否激活" name="is_active">
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="您的姓名" name="user_nickname" rules={[{ required: true, message: '请填写姓名' }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item
                        label="电子邮件"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: '请填写正确电子邮箱',
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="公司名称(与营业执照一致)" name="company_name" rules={[{ required: true, message: '请填写信息' }]}>
                        <TextArea></TextArea>
                    </Form.Item>
                    <Form.Item label="营业执照">
                            <Image src={record?.company_img}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Space>
                            <Button type="primary" htmlType="submit" onClick={onSave}>
                                保存
                            </Button>
                            <Button htmlType="reset" onClick={onCancel}>取消</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default MemberAdd;
