import React, { useState } from 'react';
import {
    DesktopOutlined,
    PieChartOutlined,
    UserOutlined,
    ApartmentOutlined,
    FileOutlined,
    ShoppingCartOutlined
} from '@ant-design/icons';
import { Breadcrumb, Layout, theme, Badge,Icon  } from 'antd';
import SideBar from "../../components/sideBar/sideBar";
import CustomHeader from "../../components/customHeader/customHeader";
import { useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import axiosInstance from '../../axios/axios.ts';
import api from '../../axios/api.ts';
const { Content, Footer } = Layout;


const Home = () => {
    const iconMap = {
        'PieChartOutlined': PieChartOutlined,
        'UserOutlined': UserOutlined,
        "DesktopOutlined": DesktopOutlined,
        "ApartmentOutlined": ApartmentOutlined,
        "FileOutlined": FileOutlined,
        "ShoppingCartOutlined": ShoppingCartOutlined
        // 添加其他图标的映射
    };
    const location = useLocation();
    const [breadItems, setBreadItems] = useState([])
    const [menuList, setMenuList] = useState([])
    useEffect(()=>{
        axiosInstance.post(api.getAdminMenu)
            .then((res: any)=>{
                res.data.forEach(item => {
                    const IconComponent = iconMap[item.icon];
                    if (IconComponent) {
                        item.icon = <IconComponent />;
                    }
                })
                setMenuList(res.data)
            })
    },[])
    useEffect(() => {
        const pathArr = location.pathname.split('/');
        pathArr.shift();
        let arr = [];
        let path = ""
        pathArr.forEach(item => {
            path += `/${item}`;
            arr.push({ title: <a href={path}>{item}</a> })
        })
        setBreadItems(arr);
    }, [location])
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <SideBar list={menuList} />
            <Layout>
                <CustomHeader />
                <Content
                    style={{
                        margin: '0 16px',
                    }}
                >
                    <Breadcrumb style={{ margin: '16px 0' }} items={breadItems}>

                    </Breadcrumb>
                    <Outlet
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                        }}
                    >
                        Bill is a cat.
                    </Outlet>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    盟虫科技 ©2023 Created by 盟虫
                </Footer>
            </Layout>
        </Layout>
    );
};
export default Home;