import React from "react";
import {Space, Button, Form, Select, Radio, message} from 'antd';
import {useNavigate} from "react-router-dom"
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";
import "./couponSend.scss"

const CouponSend: React.FC=()=>{

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [to, setTo] = React.useState<"all"| "level1"|"level2"|"level3"|"member">("all")
    const [coupon, setCoupon] = React.useState<any[]>([])
    const [userOption, setUserOption] = React.useState<any[]>([])
    const [user, setUser] = React.useState<any>()
    const [loading, setLoading] = React.useState<boolean>(false)

    const [messageApi, contextHolder] = message.useMessage();


    React.useEffect(()=>{
        axiosInstance.post(api.couponOptions)
            .then(res=>{
                console.log(res)
                setCoupon(res.data)
            })
    },[])

    const searchUser=(newVal: any)=>{
        axiosInstance.post(api.searchUser, {param: newVal})
            .then(res=>{
                console.log(res.data)
                setUser(res.data);
            })
    }

    const send=()=>{
        setLoading(true)
        form.validateFields()
            .then(val=>{
                return axiosInstance.post(api.sendCoupon, val)
            })
            .then(res=>{
                console.log(res)
                messageApi.success("发放成功");
                form.resetFields();
            })
            .finally(()=>{
                setLoading(false)
            })
            .catch(err=>{
                console.log(err)
            })
    }

    return(
        <div className="coupon-send">
            {contextHolder}
            <div className='tools'>
                <Space size="middle">
                    <Button type="primary" onClick={()=>{navigate(-1)}}>返回</Button>
                </Space>
            </div>
            <div className="content">
                <Form form={form}>
                    <Form.Item name="coupon" label="优惠券" rules={[{required: true, message: "请选择优惠券"}]}>
                        <Select options={coupon}></Select>
                    </Form.Item>
                    <Form.Item name="to" label="发送群体" initialValue="all">
                        <Radio.Group onChange={e=>{setTo(e.target.value)}}>
                            <Radio value="all">全部</Radio>
                            <Radio value="level1">注册经销</Radio>
                            <Radio value="level2">授权经销</Radio>
                            <Radio value="level3">VIP经销</Radio>
                            <Radio value="member">指定会员</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        to==="member" &&
                        <Form.Item name="user" label="用户" rules={[{required: true, message: "请选择用户"}]}>
                            <Select showSearch  mode="multiple" options={user} onSearch={searchUser}  filterOption={false}></Select>
                        </Form.Item>
                    }
                </Form>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Button loading={loading} disabled={loading} type="primary" size="large" onClick={send}>发放</Button>
                </div>
            </div>
        </div>
    )
}

export default CouponSend
