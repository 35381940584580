//自定义头部

import {Header} from "antd/lib/layout/layout";
import {Dropdown, theme} from "antd";
import { MehOutlined } from '@ant-design/icons';
import {useNavigate } from 'react-router-dom'
import './customHeader.css'


const CustomHeader = ()=>{
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    
    const navigate = useNavigate();

    const signOut = ()=>{
        localStorage.removeItem("jwtToken");
        navigate("/login")
    }

    const items = [
        {
            key: '1',
            label:(<div onClick={signOut}>退出登录</div>)
        }
    ];
    return(
        <Header style={{background: colorBgContainer}}>
           <div className={'header-container'}>
                <Dropdown placement={'bottom'} menu={{items}}>
                    <MehOutlined />
                </Dropdown>
           </div>
        </Header>
    )
}

export default CustomHeader;