/*
 * @Author: liyunlong 2581235724@qq.com
 * @Date: 2023-12-21 14:06:19
 * @LastEditors: liyunlong 2581235724@qq.com
 * @LastEditTime: 2024-01-28 19:37:54
 * @FilePath: /mc/mc_management/src/axios/api.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import baseUrl from "./baseUrl.ts";
const api = {
  login: "/admin/signIn",
  sendCode: "/admin/sendcode",
  loginWithCode: "/admin/checkcode",
  queryAdmin: "/admin/query",
  getAdminMenu: "/admin/menu",
  setAdminAuthoruty: "/admin/authority/set",
  deleteAdmin: "/admin/delete",
  queryProductByName: "/product/query/name",
  uploadImg: "/product/upload",
  uploadWang: "/product/uploadWang",
  queryPropByPage: "/prop/query",
  addProp: "/prop/add",
  getPropOptions: "/prop/options",
  delete: "/prop/delete",
  updateIndex: "/prop/update/index",
  updateAll: "/prop/update/all",
  getPropTreeOptions: "/prop/tree/options",
  getSelectOptions: "/type/getoptions",
  setTypeVisible: "/type/setvisible",
  setTypeIndex: "/type/setindex", //设置分类顺序
  addType: "/type/add",
  getType: "/type/get",
  updateType: "/type/update",
  deleteType: "/type/delete",
  deleteAllType: "/type/delete/all",
  setProp: "/type/setprop",
  getPropIdByTypeId: "/type/get/propId",
  signUp: "/admin/register", // 员工注册
  getTypeOptions: "/type/get/options", // 分类接口
  getTypeTag: "/mp/product/tag", //获取分类
  addProduct: "/product/add", // 添加商品
  addProductBath: "/product/add/batch", //批量添加商品
  getProductList: "/product/query/page", // 获取商品列表
  deleteProduct: "/product/delete", // 删除商品
  updateProduct: "/product/update", // 更新商品
  getProductFromId: "/product/query/id", // 根据id获取商品信息
  getProductFromIds: "/product/query/ids", //根据ids获取商品信息
  addSpec: "/spec/add", // 添加规格
  querySpecByPage: "/spec/query", // 查询规格
  deleteSpec: "/spec/delete", // 删除规格
  getSpecDetail: "/spec/query/detail", // 获取规格详情
  updateSpec: "/spec/update", // 更新规格
  getSpecOptions: "/spec/options", // 获取规格选项
  addUser: "/user/add", // 添加用户
  getUserOptions: "/user/options", // 获取用户选项
  setMainAccount: "/user/main/set", // 设置主账号
  getUserList: "/user/query", // 获取用户列表
  getUserById: "/user/query/id", // 根据id获取用户信息
  updateUser: "/user/update", // 更新用户信息
  addUserBatch: "/user/add/batch", // 批量添加用户
  reviewPass: "/user/review/pass", // 审核通过
  deleteUser: "/user/delete", // 删除用户
  searchUser: "/user/search", //搜索用户
  countUser: "/user/count", //计算各等级用户数量
  exportUser: "/user/export", //导出用户
  completeOrder: "/mp/order/complete", //完成订单
  deleteorder: "/mp/order/delete/ids", //批量删除订单
  getOrderDetail: "/mp/order/detail", // 获取订单详情
  getOrderList: "/mp/order/query/page", // 获取订单列表
  getOrderForsend: "/mp/order/query/forsend", // 获取待发货订单列表
  updateSendId: "/mp/order/update/sendid", // 发货
  getOrderCount: "/mp/order/getcount", //获取待发货数量
  editOrder: "/mp/order/update/admin", // 更新订单
  payOrderByAdmin: "/mp/order/pay/admin", // 管理员支付订单
  exportOrder: "/mp/order/export", //导出订单
  createWarehouse: "/warehouse/add", // 创建仓库
  editWarehouse: "/warehouse/update", // 更新仓库
  queryWarehouse: "/warehouse/query/page", // 查询仓库
  removeWarehouse: "/warehouse/remove", // 删除仓库
  getWrehouseOptions: "/warehouse/options/get", // 获取仓库选项
  setProvince: "/logistics/province/set",
  updateProvince: "/logistics/province/update", // 更新省
  addProvince: "/logistics/province/add", //添加省
  deleteProvince: "/logistics/province/delete", // 删除省
  getProvince: "/logistics/province/get",
  getProvinceOptions: "/logistics/province/options/get", //获取省下拉选项
  addCity: "/logistics/city/add", //添加市
  updateCity: "/logistics/city/update", // 更新市
  deleteCity: "/logistics/city/delete", // 删除市
  getCityOptions: "/logistics/city/options/get", //获取市下拉选项
  addRegion: "/logistics/region/add", //添加区
  updateRegion: "/logistics/region/update", // 更新区
  deleteRegion: "/logistics/region/delete", // 删除区
  getRegionOptions: "/logistics/region/options/get", //获取区下拉选项
  getPlaceTreeOptions: "/logistics/option/tree", //获取地区下拉选项
  addRegionBatch: "/logistics/add/batch", // 批量添加地区
  addShipping: "/shipping/add", // 添加发货地址
  updateShipping: "/shipping/update", // 更新发货地址
  queryShipping: "/shipping/query", // 查询发货地址
  addShippingCompany: "/shippingcompany/add", // 添加物流公司
  getShippingCompany: "/shippingcompany/get", // 查询物流公司
  updateShippingCompany: "/shippingcompany/update", // 更新物流公司
  deleteShippingCompany: "/shippingcompany/delete", // 删除物流公司
  getShippingCompanyOptions: "/shippingcompany/options/get", // 获取物流公司下拉选项
  addShippingType: "/shippingtype/add", // 添加发货方式
  getShippingType: "/shippingtype/get", // 获取发货方式列表
  updateShippingType: "/shippingtype/update", // 更新发货方式
  getShippingTypeById: "/shippingtype/getbyid", // 获取发货方式下拉选项
  deleteShippingType: "/shippingtype/delete", // 删除发货方式
  optionShippingType: "/shippingtype/query/options", //发货方式选项
  addCoupon: "/coupon/add", // 添加优惠券
  queryCoupon: "/coupon/query", // 查询优惠券
  updateCoupon: "/coupon/update", // 更新优惠券
  deleteCoupon: "/coupon/delete", // 删除优惠券
  couponOptions: "/coupon/options", //优惠券下拉框
  sendCoupon: "/coupon/send", //发放优惠券
  addMessage: "/message/add", // 添加消息模板
  queryMessage: "/message/query", // 查询消息模板
  addPrizePool: "/prizepool/add", //添加奖品池
  deletePrizePool: "/prizepool/delete",//删除奖品池
  queryPrizePool: "/prizepool/query", //查询奖品池
  queryPrizeDetailByPoolId: "/prizepool/query/detail", //查询奖品池内的奖品
  getPrizePoolOptions: "/prizepool/options", //获取奖品池下拉选项"
  addPrizePoo: "/prizepool/prize/add", //添加奖品池-奖品
  updatePrizePoo: "/prizepool/prize/update", //编辑奖品池-奖品
  deletePrize: "/prizepool/prize/delete", //删除奖品池-奖品
  queryActivity: "/activity/query", //查询活动
  addActivity: "/activity/add", //添加活动
  updateActivity: "/activity/update", //更新活动
  deleteActivity: "/activity/delete", //删除活动
  getActivityResult: "/activity/common/result/query", //获取活动中奖名单
  downloadActivityResult: "hhh", //下载活动中奖名单
  getTreeResult: "/activity/tree/result", //获取摇钱树中奖名单
  downloadTreeResult: "/activity/tree/result/excel", //下载摇钱树结果
  confirmTreePrize: "/activity/tree/setused", //确认领奖
  fuzzySearchUser: "/mp/user/fuzzy/search",//模糊搜索用户
  menuTreeData: "/menu/query/tree",//获取菜单树数据
  addAuthority: "/menu/authority/add",//添加权限
  queryAuthority: "/menu/authority/query",//查询权限
  updateAuthority: "/menu/authority/update",//更新权限
  deleteAuthority: "/menu/authority/delete",//删除权限
  getAuthorityOptions: "/menu/authority/options",//删除权限
  getReceiptList: "/mp/order/paid/orders", //获取收款单
};

for(const key in api){
    api[key] = baseUrl.baseUrl + api[key];
}

export default api;
