import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Table } from "antd";
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";
import "./index.scss";

const ReceiptList: React.FC = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);

  const columns = [
    {
      title: "单据编号",
      dataIndex: "id",
      key: "id",
      render: (_, __, index) => (page - 1) * size + (index + 1),
    },
    {
      title: "订单号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "交付金额",
      dataIndex: "pay_actual",
      key: "pay_actual",
    },
    {
      title: "支付方式",
      dataIndex: "pay_method",
      key: "pay_method",
      align: "center",
      render: (text, record) =>
        text === "bank" ? (
          <Space direction="vertical">
            <span>对公转账</span>
            <span>
              {record.receive_bank}: {record.receive_account}
            </span>
          </Space>
        ) : text === "wechat" ? (
          "微信付款"
        ) : (
          "未付款"
        ),
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
      render: (text) => {
        return <span>{new Date(text).toLocaleString()}</span>;
      },
    },
    {
      title: "操作",
      key: "option",
      render: (_, record) => {
        return (
          <Space>
            <a onClick={() => toDetail(record.id)}>查看详情</a>
          </Space>
        );
      },
    },
  ];

  const pageChange = (page: number, size: number) => {
    setPage(page);
    setSize(size);
  };

  const paginationSetting = {
    current: page,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    total: total,
    onChange: pageChange,
  };

  useEffect(() => {
    loadDate();
  }, [page, size]);

  const loadDate = (newPage?: number) => {
    setTableLoading(true);

    const pageDto = {
      page: newPage || page,
      pageSize: size,
    };

    axiosInstance
      .post(api.getReceiptList, pageDto)
      .then((res) => {
        setTableData(res.data.result);
        setTotal(res.data.total);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const toDetail = (id) => {
    navigate(`/orderlist/edit?id=${id}`);
  };

  return (
    <div className="receipt-list">
      <div className="table">
        <Table
          key="id"
          columns={columns}
          dataSource={tableData}
          pagination={paginationSetting}
          loading={tableLoading}
        />
      </div>
    </div>
  );
};

export default ReceiptList;
