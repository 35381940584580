import React, { useState, useEffect } from "react";
import { Button, Space, Table, Modal, message, Form, Input, Select, InputNumber, TreeSelect, Radio, Popconfirm } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";
import Link from "antd/es/typography/Link";

import './shippingType.scss';

const ShippingType: React.FC = () => {
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [companyOptions, setCompanyOptions] = useState<any[]>([]);
    const [provinceOptions, setProvinceOptions] = useState<any[]>([]);
    const [type, setType] = useState<any>('WEIGHT');
    const [messageApi, contextHolder] = message.useMessage();
    const [disable, setDisable] = useState<boolean>(false);
    const [submitType, setSubmitType] = useState<string>('add'); // 用于区分是新增还是编辑操作，默认为新增add，编辑时为edit

    useEffect(() => {
        axiosInstance.post(api.getShippingCompanyOptions)
            .then(res => {
                setCompanyOptions(res.data)
            })
        axiosInstance.post(api.getPlaceTreeOptions).then(res => {
            setProvinceOptions(res.data);
        })

    }, [])

    useEffect(() => {
        loadDate();
    }, [page, size])

    useEffect(() => {
        if (selectedRowKeys.length > 0) {
            setDisable(false)
        } else {
            setDisable(true)
        }
    }, [selectedRowKeys])

    const loadDate = (newPage?: number) => {
        setTableLoading(true)
        const pageDto = {
            page: newPage || page,
            size: size
        }
        axiosInstance.post(api.getShippingType, pageDto)
            .then(res => {
                setTableData(res.data.result)
                setTotal(res.data.total)
            })
            .finally(() => {
                setTableLoading(false)
            })
    }


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const pageChange = (page: number, size: number) => {
        setPage(page);
        setSize(size);
    }

    const submit = () => {
        form.validateFields().then(values => {
            if (submitType === 'add') {
                axiosInstance.post(api.addShippingType, values).then(res => {
                    if ((res as any).code === 0) {
                        messageApi.success("添加成功");
                        setVisible(false);
                        loadDate();
                    } else {
                        messageApi.error((res as any).message);
                    }
                })
            } else {
                axiosInstance.post(api.updateShippingType, values).then(res => {
                    if ((res as any).code === 0) {
                        messageApi.success("修改成功");
                        setVisible(false);
                        loadDate();
                    } else {
                        messageApi.error((res as any).message);
                    }
                })
            }

        }).catch(err => {
            console.log(err);
        })
    }

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleEdit = (record) => {
        setSubmitType("edit");
        setVisible(true);
        form.setFieldValue("name", record.name);
        form.setFieldValue("id", record.id);
        form.setFieldValue("companyId", record.company.id);
        form.setFieldValue("type", record.type);
        form.setFieldValue("initialWeight", record.initialWeight);
        form.setFieldValue("initialPrice", record.initialPrice);
        form.setFieldValue("additionalWeight", record.additionalWeight);
        form.setFieldValue("additionalPrice", record.additionalPrice);
        form.setFieldValue("desc", record.desc)
        form.setFieldValue("areaFees", record.areaFees);
    }

    const treeChange = (value, label, extra) => {
        console.log(value, label, extra);

    }

    const handleDelete = (record) => {
        axiosInstance.post(api.deleteShippingType, { id: record.id })
        .then(res => {
            message.success("删除成功");
            loadDate();
        })
    }

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            title: '配送方式',
        },
        {
            dataIndex: "companyId",
            key: 'companyId',
            title: '快递公司',
            render: (text, record) =>
                <Space key={record.id}>
                    <span>{record.company.name}</span>
                </Space>
        },
        {
            dataIndex: "desc",
            key: 'desc',
            title: '描述',
        },
        {
            dataIndex: "weight",
            key: 'weight',
            title: "首重|续重",
            render: (text, record) =>
                <Space key={"item" + record.id}>
                    <span>{record.initialWeight}|{record.additionalWeight}</span>
                </Space>
        },
        {
            dataIndex: "price",
            key: 'price',
            title: "首重费|续重单价",
            render: (text, record) =>
                <Space key={"item" + record.id}>
                    <span>{record.initialPrice}|{record.additionalPrice}</span>
                </Space>
        },
        {
            title: "操作",
            key: 'edit',
            render: (record) => {
                return (
                    <Space key={"item" + record.id}>
                        <Link onClick={() => handleEdit(record)}>编辑</Link>
                        <Popconfirm onConfirm={() => handleDelete(record)} title="警告" okType="danger" okText="删除" description="确定要删除吗" cancelText="取消">
                            <span style={{ color: "red", cursor: "pointer" }}>删除</span>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]

    return (
        <div className="shipping-type">
            {contextHolder}
            <div className='tools'>
                <Space size="large">
                    <Button type='primary' onClick={() => { setVisible(true); setSubmitType("add") }}>添加配送方式</Button>
                    <Button danger disabled={disable}>批量删除</Button>
                </Space>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={paginationSetting} loading={tableLoading} />
            </div>
            <Modal
                title="配送方式"
                closable={false}
                okText="确认"
                cancelText="取消"
                open={visible}
                onCancel={() => { setVisible(false); form.resetFields() }}
                onOk={submit}
                width={800}
                destroyOnClose
                maskClosable={false}
            >
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    form={form}
                >
                    <Form.Item name="id" label="ID" hidden></Form.Item>
                    <Form.Item name="name" label="配送方式" rules={[{ required: true, message: '请填写配送方式' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="companyId" label="物流公司" rules={[{ required: true, message: '请填写物流公司' }]}>
                        <Select options={companyOptions} />
                    </Form.Item>
                    <Form.Item name="type" label="计费方式" initialValue={"WEIGHT"}>
                        <Radio.Group onChange={e => { setType(e.target.value) }}>
                            <Radio value="WEIGHT">按重量</Radio>
                            <Radio value="VOLUME">按体积</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="initialWeight"
                        label={type === "WEIGHT" ? "首重" : "首体积"}
                        rules={[{ required: true, message: type === "WEIGHT" ? '请填写首重' : "请填写首体积" }]}
                    >
                        <InputNumber style={{ width: "100%" }} addonAfter={type === "WEIGHT" ? "KG" : "cm³"} />
                    </Form.Item>
                    <Form.Item name="initialPrice" label={type === "WEIGHT" ? "首重费用" : "首体积费用"} rules={[{ required: true, message: '请填写首重费用' }]}>
                        <InputNumber addonBefore="￥" style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item name="additionalWeight" label={type === "WEIGHT" ? "续重" : "续体积"} rules={[{ required: true, message: type === "WEIGHT" ? '请填写续重' : "请填写续体积" }]}>
                        <InputNumber style={{ width: "100%" }} addonAfter={type === "WEIGHT" ? "KG" : "cm³"} />
                    </Form.Item>
                    <Form.Item name="additionalPrice" label={type === "WEIGHT" ? "续重费用" : "续体积费用"} rules={[{ required: true, message: type === "WEIGHT" ? '请填写续重费用' : "请填写续体积费用" }]}>
                        <InputNumber addonBefore="￥" style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.List name="areaFees" initialValue={[{}]}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <div key={key} className="form-list">
                                        <div className="list-item">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'areas']}
                                                rules={[{ required: true, message: '请选择省份' }]}
                                            >
                                                {/* 省份选择器，替换成省份选择的组件 */}
                                                <TreeSelect showCheckedStrategy={TreeSelect.SHOW_PARENT}  placeholder="省份" treeData={provinceOptions} multiple treeCheckable onChange={treeChange} />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'initialPrice']}
                                                rules={[{ required: true, message: type === "weight" ? '请输入首重费用' : '请输入首体积费用' }]}
                                            >
                                                <InputNumber addonBefore="￥" placeholder={type === "WEIGHT" ? "首重费用" : "首体积费用"} style={{ width: "100%" }} />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'additionalPrice']}
                                                rules={[{ required: true, message: type === "WEIGHT" ? '请输入续重费用' : '请输入续体积费用' }]}
                                            >
                                                <InputNumber addonBefore="￥" placeholder={type === "WEIGHT" ? "续重费用" : "续体积费用"} style={{ width: "100%" }} />
                                            </Form.Item>
                                        </div>
                                        {
                                            index > 0 &&
                                            <MinusCircleOutlined style={{ color: "red" }} onClick={() => remove(name)} />
                                        }
                                    </div>
                                ))}
                                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
                                    <Button style={{ width: "83.6%" }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        添加配送区域
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form.List>
                    <Form.Item name="desc" label="配送方式描述">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ShippingType;