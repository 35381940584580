/*
 * @Author: liyunlong 2581235724@qq.com
 * @Date: 2023-12-21 14:06:19
 * @LastEditors: liyunlong 2581235724@qq.com
 * @LastEditTime: 2023-12-21 17:46:35
 * @FilePath: /mc/mc_management/src/pages/signUp/signUp.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import { Button, Form, Input, Space, message } from "antd";
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import api from '../../axios/api.ts';
import './signUp.scss';

const SignUp: React.FC = () => {

    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const onSubmit = () => {
        form
        .validateFields()
        .then(values => {
            // 在这里可以处理表单验证通过后的逻辑
            axios.post(api.signUp, {
                u_name: values.username,
                u_password: values.password,
                u_phone: values.phone,
                u_nickname: values.nickname,
                u_department: values.department,
            })
                .then(
                    (res) => {
                        if (res.data.code === 0){
                            navigate("/login");
                        } else {
                            messageApi.error(res.data.msg)
                        }
                    }
                )
        })
        .catch(err => {
            // 在这里可以处理表单验证失败后的逻辑
            messageApi.error(err)
        });

    }

    const onReset = () => {
        form.resetFields();
    }

    return (
        <div className="signup">
            {contextHolder}
            <div className="container">
                <div className="title">员工注册</div>
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                >
                    <Form.Item
                        label="账号"
                        name="username"
                        rules={[{ required: true, message: "请填写用户名" }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[{ required: true, message: "请填写密码" }]}
                    >
                        <Input.Password size="large" />
                    </Form.Item>
                    <Form.Item
                        label="确认密码"
                        name="confirmPassword"
                        rules={[
                            {
                                required: true,
                                message: '请确认密码',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('密码和上面密码不同'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password size="large" />
                    </Form.Item>
                    <Form.Item
                        label="姓名"
                        name="nickname"
                        rules={[{ required: true, message: "请填写姓名" }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="手机号"
                        name="phone"
                        rules={[
                            { required: true, message: "请填写手机号" },
                            { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号' }
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="部门"
                        name="department"
                        rules={[{ required: true, message: "请填写部门" }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <div className="btn-container">
                        <Space size="large">
                            <Button onClick={onSubmit} type="primary" htmlType="submit" size="large">注册</Button>
                            <Button onClick={onReset} size="large">重置</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default SignUp;