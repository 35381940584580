import axios from 'axios';
import { message } from 'antd';

interface responseData {
  code: number;
  msg: string;
  data: any;
}
const baseURL = process.env.BASE_URL;

const axiosInstance = axios.create({
  baseURL: baseURL, // 替换为您的 API 基础 URL
  timeout: 20000, // 设置请求超时时间
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    // 获取存储的 JWT token
    const token = localStorage.getItem('jwtToken'); // 或从其他地方获取，如 Redux store, sessionStorage

    // 如果 token 存在，则将其添加到请求头中
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  response => {
    return response.data; // 确保返回处理后的数据
  },
  error => {
    if (error.request.status === 401) {
      message.error("登录过期", 3);
      window.location.href = "/login"

    } else if(error.request.status ===403){
        message.error("暂无权限，联系管理员添加",3);
    }
    else {
      // 响应错误处理
      return Promise.reject(error.response);
    }

  }
);

export default axiosInstance;