import React, { useState } from 'react';
import { Tree, Button, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

interface TreeNode {
    title: string;
    key: string;
    children?: TreeNode[];
}

interface TreeFormProps {
    onChange: (params: TreeNode[]) => void;
    initialData?: TreeNode[];
}

const TreeForm: React.FC<TreeFormProps> = ({ onChange, initialData }) => {
    const [treeData, setTreeData] = useState<TreeNode[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedKey, setSelectedKey] = useState<string | null>(null);
    const [nodeId, setNodeId] = useState(0);

    React.useEffect(() => {
        onChange(treeData)
    }, [treeData])

    React.useEffect(() => {
        if (initialData) {
            setTreeData(initialData);
        }
    }, [initialData])

    const onAddNode = () => {
        if (!inputValue) return;

        const newNode: TreeNode = { title: inputValue, key: nodeId.toString() };
        setNodeId(uuidv4());
        setInputValue('');

        if (selectedKey === null) {
            setTreeData([...treeData, newNode]);
        } else {
            const addNode = (nodes: TreeNode[]): TreeNode[] =>
                nodes.map(node => {
                    if (node.key == selectedKey) {
                        return { ...node, children: [...(node.children || []), newNode] }
                    } else {
                        return { ...node, children: node.children ? addNode(node.children) : [] }
                    }
                });

            setTreeData(addNode(treeData));
        }
    };

    const onDeleteNode = () => {
        if (selectedKey === null) return;

        const deleteNode = (nodes: TreeNode[], key: string): TreeNode[] =>
            nodes
                .filter(node => node.key !== key)
                .map(node => ({
                    ...node,
                    children: node.children ? deleteNode(node.children, key) : [],
                }));
        const newTreeData = deleteNode(treeData, selectedKey);
        console.log("treeData",newTreeData)
        setTreeData(newTreeData);
        setSelectedKey(null);
    };

    const onEditNode = () => {
        if (selectedKey === null) return;

        const editNode = (nodes: TreeNode[], key: string): TreeNode[] =>
            nodes.map(node => {
                if (node.key == key) {
                    return { ...node, title: inputValue };
                } else {
                    return {
                        ...node,
                        children: node.children ? editNode(node.children, key) : [],
                    };
                }
            });

        const newTreeData = editNode(treeData, selectedKey);
        setTreeData(newTreeData);
        setInputValue('');
        setSelectedKey(null);
    };

    return (
        <div>
            <Input value={inputValue} onChange={e => setInputValue(e.target.value)} />
            <div style={{ display: "flex", margin: "10px 0" }}>
                <Button style={{ marginRight: "20px" }} onClick={onAddNode}>添加节点</Button>
                <Button style={{ marginRight: "20px" }} disabled={!selectedKey} onClick={onEditNode}>编辑节点</Button>
            </div>
            <Tree
                defaultExpandAll={true}
                showLine
                switcherIcon={<DownOutlined />}
                treeData={treeData}
                onSelect={(selectedKeys, { selectedNodes }) => {
                    setSelectedKey(selectedKeys.length > 0 ? selectedKeys[0].toString() : null);
                }}
            />
        </div>
    );
};

export default TreeForm;
