import {Menu} from "antd";
import {useState} from "react";
import Sider from "antd/es/layout/Sider";
import { useNavigate } from 'react-router-dom';
import './sideBar.css'


const SideBar = (props) => {
    const navigate = useNavigate();
    const onclick=(args)=>{
        navigate(args.key)
    }

    const [collapsed, setCollapsed] = useState(false);
    return (
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <div className="logo"><img src={'https://mengchong-1322498485.cos.ap-shanghai.myqcloud.com/WEX%27.png'}/>盟虫闪店</div>
            <Menu onClick={onclick} darkitembg="#010101" theme="dark" defaultSelectedKeys={['skulist']} defaultOpenKeys={['sku','order']} mode="inline" items={props.list}/>
        </Sider>
    )

}

export default SideBar;