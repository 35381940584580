import React from "react";
import { Space, Button, Table, Modal, Form, Popconfirm, message, Select,InputNumber } from 'antd';
import AddType from '../../components/addType/addType.tsx';
import axiosInstance from '../../axios/axios.ts';
import api from '../../axios/api.ts'
import { EditOutlined } from '@ant-design/icons';
import Link from "antd/es/typography/Link";
import './typeList.scss';
interface PageDto {
    page?: number;
    size?: number;
}
interface TreeNode {
    title: string;
    key: string;
    children?: TreeNode[];
}

const TypeList: React.FC = () => {
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [disable, setDisable] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
    const [tableLoading, setTableLoading] = React.useState<boolean>(false);
    const [tableData, setTableData] = React.useState([]);
    const [page, setPage] = React.useState<number>(1);
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [total, setTotal] = React.useState<number>(0);
    const [title, setTitle] = React.useState<string>("添加产品分类");
    const [treeData, setTreeData] = React.useState();
    const [editData, setEditData] = React.useState<TreeNode[]>([]);
    const [options, setOptions] = React.useState<{ label: string, value: string }[]>([]);
    const [editingRecord, setEditingRecord] = React.useState(null);
    const [index, setIndex] = React.useState();




    const [form] = Form.useForm();


    React.useEffect(() => {
        if (selectedRowKeys.length === 0) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [selectedRowKeys])

    React.useEffect(() => {
        loadDate();
        loadOptions();
    }, [page, pageSize])

    const loadOptions = () => {
        // 加载分类
        axiosInstance.post(api.getPropOptions)
            .then(res => {
                console.log(res);
                setOptions(res.data);
            })
    }


    const loadDate = (newPage?: number) => {
        setTableLoading(true)
        const pageDto: PageDto = {
            page: newPage || page,
            size: pageSize
        }
        axiosInstance.post(api.getType, pageDto)
            .then(res => {
                setTotal(res.data.total);
                //@ts-expect-error ensure the value
                setTableData(addKey(res.data.data));
            })
            .finally(() => {
                setTableLoading(false)
            })
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const deleteAllType = () => {
        axiosInstance.post(api.deleteAllType, { id: selectedRowKeys })
            .then(() => {
                message.success("删除成功")
                loadDate();
                setSelectedRowKeys([]);
            })

    }

    const handleSubmit = () => {
        setSubmitLoading(false);
        if (editData.length !== 0) {
            updataType();
            return;
        }
        axiosInstance.post(api.addType, treeData)
            .then(() => {
                loadDate();
            })
            .finally(() => {
                setSubmitLoading(false);
                setIsModalOpen(false);
                setEditData([]);
            })

    }

    const updataType = () => {
        axiosInstance.post(api.updateType, treeData)
            .then(() => {
                message.success("更新成功");
                loadDate();
            })
            .finally(() => {
                setSubmitLoading(false);
                setIsModalOpen(false);
                setEditData([]);
            })
    }

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setPageSize(size);
    }

    const cancel = () => {
        setSubmitLoading(false);
        setIsModalOpen(false);
        setEditData([])
    }

    const handleTreeChange = (treeData) => {
        setTreeData(treeData)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const addKey = (data: { id: string, key: string, children?: any[] }[]) => {
        data.map(item => {
            item.key = item.id;
            if (item.children?.length) {
                addKey(item.children)
            }
        })
        return data;
    }

    const handleEdit = (record) => {
        setEditData([renameTypeNameToTitle(record)]);
        setIsModalOpen(true);
    }

    const renameTypeNameToTitle = (obj) => {
        // 重命名 type_name 为 title
        const { type_name, children, ...rest } = obj;
        const newObj = { ...rest, title: type_name };

        // 如果存在 children 属性，递归处理每个子对象
        if (children && Array.isArray(children)) {
            newObj.children = children.map(child => renameTypeNameToTitle(child));
        }
        return newObj;
    }

    const deleteType = (record) => {
        console.log(record);
        axiosInstance.post(api.deleteType, { id: record.id })
            .then(() => {
                message.success("删除成功");
                loadDate();
            })
    }

    const setProp=(propId, record)=>{
        console.log(propId,record);
        axiosInstance.post(api.setProp,{id:record.id,propId})
        .then(res=>{
            console.log(res);
            message.success("设置成功");
            loadDate();
        })
    }

    const setVisible=(id)=>{
        axiosInstance.post(api.setTypeVisible,{id})
            .then(res=>{
                loadDate();
                message.success("设置成功");
            })
    }

    const startEdit = (record) => {
        setEditingRecord(record);
    };

    const confirmIndex=()=>{
        axiosInstance.post(api.setTypeIndex,{id:(editingRecord as any).id,index})
            .then(res=>{
                message.success("更新成功",3);
                loadDate();
            })
    }

    const columns = [
        {
            title: '分类名称',
            dataIndex: 'type_name',
        },
        {
            title: "产品属性",
            render: (record) => {
                return (
                    <Select style={{minWidth:"150px"}} placeholder="请选择商品属性" defaultValue={Number(record.propId)} options={options} onChange={(e)=>{setProp(e, record)}}></Select>
                )
            }
        },
        {
            title: '顺序',
            render: (record) => {
                return (
                    <div>
                        <span>{record.index}</span>
                        <Popconfirm
                            title="编辑"
                            //@ts-expect-error ensure the value
                            description={(<InputNumber onChange={val => { setIndex(val) }} />)}
                            trigger="click"
                            okText="确认"
                            cancelText="取消"
                            onConfirm={confirmIndex}
                        >
                            <EditOutlined style={{ cursor: "pointer" }} onClick={() => startEdit(record)} />
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            title: '操作',
            render: (record) => {
                return (
                    <Space key={"item" + record.id}>
                        {
                            record.parent===null &&
                            <a onClick={()=>setVisible(record.id)}>{record.visible?"隐藏":"显示"}</a>
                        }
                        <Link onClick={() => handleEdit(record)}>编辑</Link>
                        <Popconfirm title="确认删除？" okText="确认" cancelText="取消" onConfirm={() => deleteType(record)}>
                            <Link>删除</Link>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ]

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }

    return (
        <div className="type-list">
            <div className='tools'>
                <Space size="middle">
                    <Button type='primary' onClick={() => { setIsModalOpen(true); setTitle("添加产品分类") }}>添加分类</Button>
                    <Button danger disabled={disable} onClick={deleteAllType}>批量删除</Button>
                </Space>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={paginationSetting} scroll={{ y: "800" }} loading={tableLoading} />
            </div>
            <Modal
                open={isModalOpen}
                width={700}
                closable={false}
                title={title}
                onOk={handleSubmit}
                onCancel={cancel}
                okText="提交"
                confirmLoading={submitLoading}
                cancelText="取消">
                <AddType initialData={editData} onChange={handleTreeChange} />
            </Modal>
        </div>
    )
}

export default TypeList;