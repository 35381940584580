import React, { useState, useEffect } from 'react'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import api from '../../axios/api.ts'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import './editor.scss';

interface MyEditorProps {
    className?: string;
    value: string;
    onChange: (value) => void;
}

const MyEditor: React.FC<MyEditorProps> = ({ className = "", value, onChange }) => {
    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null)

    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {}
    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {    // TS 语法
        placeholder: '请输入内容...',
        MENU_CONF: {
            uploadImage: {
                async customUpload(file: File, insertFn){
                    if(file){
                        const formData = new FormData();
                        formData.append("image", file);
                        fetch(api.uploadWang,{
                            method: "POST",
                            body: formData
                        })
                        .then(response=>response.json())
                        .then(res=>{
                            console.log(res)
                            if(res.errno===0){
                                insertFn(res.data.url)
                            }
                        })
                        .catch(err=>{
                            console.log(err)
                        })
                    }
                }
            },
        },
    }
    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    return (
        <>
            <div
                className={`default-class ${className}`}
                style={{ border: '1px solid #ccc', zIndex: 100, marginBottom: "15px" }}
            >
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={value}
                    onCreated={setEditor}
                    onChange={editor => onChange(editor.getHtml())}
                    mode="default"
                    style={{ height: '500px', overflowY: 'hidden' }}
                />
            </div>
        </>
    )
}

export default MyEditor;