import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Table,
  Modal,
  Form,
  Input,
  message,
  Popconfirm,
} from "antd";
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";

const ShippingCompany: React.FC = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false); // 控制弹窗显示与隐藏
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [type, setType] = useState<string>("add");
  const [delDisable, setDelDisable] = useState<boolean>(true);
  const [record, setRecord] = useState<any>({});

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setDelDisable(false);
    } else {
      setDelDisable(true);
    }
  }, [selectedRowKeys]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const pageChange = (page: number, size: number) => {
    setPage(page);
    setSize(size);
    loadData(page, size);
  };

  const paginationSetting = {
    current: page,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    total: total,
    onChange: pageChange,
  };

  const loadData = (newPage = page, newSize = size) => {
    setTableLoading(true);
    const pageDto = {
      page: newPage,
      size: newSize,
    };
    axiosInstance
      .post(api.getShippingCompany, pageDto)
      .then((res) => {
        setTableData(res.data.result);
        setTotal(res.data.total);
        setTableLoading(false);
      })
      .catch((err) => {
        setTableLoading(false);
      });
  };

  const submit = () => {
    form.validateFields().then((res) => {
      if (type === "add") {
        axiosInstance.post(api.addShippingCompany, res).then(() => {
          message.success("添加成功");
          setVisible(false);
          loadData();
        });
      } else if (type === "edit") {
        res.id = record.id;
        axiosInstance.post(api.updateShippingCompany, res).then(() => {
          message.success("修改成功");
          setVisible(false);
          loadData();
        });
      }
    });
  };

  const add = () => {
    setType("add");
    setVisible(true);
  };

  const edit = (record) => {
    setVisible(true);
    setRecord(record);
    form.setFieldsValue(record);
    setType("edit");
  };

  const deleteCompany = (ids: string[]) => {
    axiosInstance.post(api.deleteShippingCompany, { ids }).then(() => {
      message.success("删除成功");
      loadData();
    });
  };

  const columns = [
    {
      dataIndex: "name",
      key: "name",
      title: "物流公司",
    },
    {
      dataIndex: "website",
      key: "website",
      title: "网址",
    },
    {
      key: "action",
      title: "操作",
      render: (_, record) => {
        return (
          <Space size="middle">
            <a type="primary" onClick={() => edit(record)}>
              编辑
            </a>
            <Popconfirm
              cancelText="取消"
              okText="确定"
              okType="danger"
              title="提示"
              description="确认要删除吗？"
              onConfirm={() => deleteCompany([record.id])}
            >
              <a style={{ color: "red" }}>删除</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="shiiping-company">
      <div className="tools">
        <Space size="large">
          <Button type="primary" onClick={add}>
            添加物流公司
          </Button>
          <Button
            danger
            disabled={delDisable}
            onClick={() => deleteCompany(selectedRowKeys)}
          >
            批量删除
          </Button>
        </Space>
      </div>
      <div className="table">
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          pagination={paginationSetting}
          loading={tableLoading}
        />
      </div>
      <Modal
        open={visible}
        okText="确认"
        cancelText="取消"
        onOk={submit}
        onCancel={() => setVisible(false)}
        title="物流公司"
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item
            name="name"
            label="物流公司名称"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="website" label="网址">
            <Input />
          </Form.Item>
          <Form.Item name="code" label="代码" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <a href="//api.kuaidi100.com/manager/openapi/download/kdbm.do">
            下载编码表格
          </a>
        </Form>
      </Modal>
    </div>
  );
};

export default ShippingCompany;
