import React from "react";
import { Form, Input, Button, Space, FormInstance, Radio, Upload, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import api from "../../axios/api.ts";
import './addSpec.scss'

interface AddSpecProps {
    form: FormInstance,
    initialData: any
}

const AddSpec: React.FC<AddSpecProps> = ({ form, initialData }) => {
    const [loading, setLoading] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState<string>();
    const [fileList, setFileList] = React.useState<UploadFile<any>[]>([]);
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };

    React.useEffect(() => {
        if (initialData) {
            const initialValues = {
                spec_id: initialData.id,
                spec_name: initialData.name,
                spec_desc: initialData.desc,
                spec_type: initialData.type,
                spec_items: initialData.items.map(item => ({
                    id: item.id, // 添加 id
                    key: item.key,
                    value: item.value,
                    fileList: item.img ? [{
                        uid: item.id,
                        name: 'Image',
                        status: 'done',
                        url: item.img
                    }] : [],
                    imageUrl: item.img
                }))
            };
            form.setFieldsValue(initialValues);
        }
    }, [initialData])

    const uploadButton = (loading) => {
        return (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        )
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = async (info: UploadChangeParam<UploadFile<any>>, name: number[]) => {
        const newFileList = info.fileList;
        let newImageUrl = '';
        if (info.file.status === 'uploading') {
            setLoading(true);
        }

        if (info.file.status === 'done') {
            console.log(info)
            newImageUrl = info.file.response.data.url;

        }
        setLoading(false);
        setFileList(newFileList);
        setImageUrl(newImageUrl);

        // 更新特定字段的 fileList 和 imageUrl
        const newFields = form.getFieldValue('spec_items') || [];
        const updatedFields = newFields.map((field, index) => {
            if (index === name[0]) {
                return {
                    ...field,
                    fileList: newFileList,
                    imageUrl: newImageUrl,
                };
            }
            return field;
        });
        console.log(updatedFields);

        form.setFieldsValue({ spec_items: updatedFields });
    };

    return (
        <Form
            {...layout}
            form={form}
        >
            <Form.Item name="spec_id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item name="spec_name" label="规则名" required>
                <Input />
            </Form.Item>
            <Form.Item name="spec_desc" label="规则备注">
                <Input />
            </Form.Item>
            <Form.Item name="spec_type" label="显示类型" initialValue={"img"}>
                <Radio.Group>
                    <Radio value={"img"}>图片</Radio>
                    <Radio value={"txt"}>文字</Radio>
                </Radio.Group>
            </Form.Item>
            <div className="title">
                <div style={{ width: "25%" }}>规格名称</div>
                <div style={{ width: "25%" }}>规格值别名</div>
                <div style={{ width: "20%" }}>图片</div>
                <div style={{ textAlign: "left", width: "20%" }}>操作</div>
            </div>
            <Form.List name={"spec_items"} >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name }, index) => {
                            // 从表单状态中检索 fileList 和 imageUrl
                            const item = form.getFieldValue(['spec_items', index]) || {};
                            const fileList = item.fileList || [];
                            const imageUrl = item.imageUrl;
                            const loading = item.loading || false;
                            return (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8, alignItems: "center" }}>
                                    <Form.Item
                                        name={[name, 'key']}
                                        rules={[{ required: true, message: 'Missing first name' }]}
                                    >
                                        <Input placeholder="规格值名称" />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, 'value']}
                                        rules={[{ required: true, message: 'Missing last name' }]}
                                    >
                                        <Input placeholder="规格值别名" />
                                    </Form.Item>
                                    <Form.Item name={[name, 'file']}>
                                        <Upload
                                            name="file"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            action={api.uploadImg}
                                            beforeUpload={beforeUpload}
                                            onChange={(info) => handleChange(info, [index])}
                                            fileList={fileList}
                                        >
                                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton(loading)}
                                        </Upload>
                                    </Form.Item>
                                    <MinusCircleOutlined className="delete-btn" onClick={() => remove(name)} />
                                </Space>
                            )
                        })}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加产品规格详情
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List >
        </Form>
    )
}

export default AddSpec;