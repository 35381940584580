import {Table, Space, Modal,Form, Select, message, Switch, Input } from 'antd';
import {useEffect, useState}  from "react";
import axiosInstance  from "../../axios/axios.ts";
import api from '../../axios/api.ts'
import './index.scss';
const AdminSettingPage = () => {
  const [form] = Form.useForm();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [visiable, setVisiable] = useState(false)
  const [menuOptions, setMenuOptions] = useState<any[]>([]);
  const [editRecord, setEditRecord] = useState<any>({})

  useEffect(() => {
    loadData()
    axiosInstance.post(api.getAuthorityOptions)
        .then((res: any)=>{
           setMenuOptions(res.data)
        })
  }, []);

  const loadData = (newPage?: number, searchParams = {} as any) => {
    setTableLoading(true)
    const params={
      page: newPage || page,
      pageSize: size,
    }
    axiosInstance.post(api.queryAdmin, params)
        .then((res: any)=>{
           setData(res.data.result)
          setTotal(res.data.total)
        })
        .finally(()=>{
          setTableLoading(false)
        })
  }

  const handleEdit = (record: any) => {
    setEditRecord(record)
    setVisiable(true)
    form.setFieldsValue({
      authority: record?.authority?.id,
      enable: record.is_active
    })
  }

  const handleDelete = (id: string) => {
    axiosInstance.post(api.deleteAdmin, {id})
        .then((res: any)=>{
          if(res.code===0){
            message.success('删除成功')
            loadData()
          }else{
            message.error('删除失败')
          }
        })
  }

  const handleSubmit = () => {
    form.validateFields().then(values => {
      const params = {
        adminId: editRecord.id,
        authorityId: form.getFieldValue('authority'),
        enable: form.getFieldValue('enable'),
        password: form.getFieldValue('password')
      }
      axiosInstance.post(api.setAdminAuthoruty, params)
          .then((res: any) => {
            message.success('修改成功')
            setVisiable(false)
            loadData()
          })
          .catch((error: any) => {
            message.error('修改失败')
          })
    })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '用户名',
      dataIndex: 'u_name',
      key: 'u_name',
    },
    {
      title: '手机号',
      dataIndex: 'u_phone',
      key: 'u_phone',
    },
    {
      title: "姓名",
      dataIndex: 'u_nickname',
      key: 'u_nickname',
    },
    {
      title: "身份",
      key: "authority",
      render: (_, record)=>{
        return record?.authority?.name
      }
    },
    {
      title: "账号状态",
      key: "is_active",
      render: (_, record)=>{
        return record?.is_active? <div className={"status"}><div className={"enabled"}></div><div>启用</div></div>: <div className={"status"}><div className={"disabled"}></div><div>禁用</div></div>
      }
    },
    {
      title: "操作",
      key: "action",
      render: (text: any, record: any) => (
          <Space>
            <a onClick={()=>handleEdit(record)}>编辑</a>
            <a style={{color:'red'}} onClick={()=>handleDelete(record.id)}>删除</a>
          </Space>
      )
    }
  ]

  const pageChange = (page: number, size: number) => {
    setPage(page);
    setSize(size);
    loadData(page)
  }


  const paginationSetting = {
    current: page,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    total: total,
    onChange: pageChange
  }
  return (
      <div className='admin-setting'>
        <div className='tools'></div>
        <div className='table'>
          <Table
            columns={columns}
            dataSource={data}
            loading={tableLoading}
            pagination={paginationSetting}
          />
        </div>
        <Modal
          title="编辑管理员"
          open={visiable}
          onCancel={() => setVisiable(false)}
          onOk={handleSubmit}
        >
          <Form form={form}>
            <Form.Item name='authority' label="身份">
              <Select
                  options={menuOptions}
              ></Select>
            </Form.Item>
            <Form.Item name='enable' label="账号状态">
              <Switch checkedChildren="启用" unCheckedChildren="禁用"></Switch>
            </Form.Item>
            <Form.Item name='password' label='更新密码'>
              <Input placeholder='如需更新密码，请填写'/>
            </Form.Item>
          </Form>
        </Modal>
      </div>
  );
};

export default AdminSettingPage;