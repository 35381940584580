import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";
import { Space, Button, Table, Form } from "antd";

const Message = () => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        loadDate()
    }, [page, size])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setSize(size);
    }


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }

    const loadDate = (newPage?: number, searchParams = {} as any) => {

        setTableLoading(true)
        const pageDto = {
            page: newPage || page,
            size: size,
            params: {
                id: searchParams?.id || null,
                company_name: searchParams?.company_name || null,
                is_active: searchParams?.is_active === null ? null : searchParams?.is_active,
                user_phone: searchParams?.user_phone || null,
                user_level: searchParams?.user_level || null,

            }
        }
        axiosInstance.post(api.queryMessage, pageDto)
            .then(res => {
                res.data.result.forEach((item, index) => {
                    item.key = index;
                })
                setTableData(res.data.result)
                setTotal(res.data.total)
            })
            .finally(() => {
                setTableLoading(false)
            })

    }

    const columns = [
        {
            title: "标题",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "接收人公司",
            key: "action",
            render: (_,record)=>{
                return <span>{record.user.company_name}</span>
            }
        },
        {
            title: "接收人",
            key: "name",
            render: (_,record)=>{
                return <span>{record.user.user_nickname}</span>
            }
        }
    ]

    return (
        <div className="msg">
            <div className='tools'>
                <Space size="large">
                    <Button type='primary' onClick={() => navigate(`/message/add`)}>创建消息</Button>
                </Space>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={paginationSetting} loading={tableLoading} />
            </div>
        </div>
    )
}

export default Message;