
import {Button, Table, Modal, Form, Input, TreeSelect, message, Space} from 'antd'
import {useState, useEffect} from "react";
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";

const AuthorityPage = () => {
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [mode, setMode] = useState<"add"| "edit">('add')
    const [treeData, setTreeData] = useState([])
    const [editId, setEditId] = useState(0)
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [data, setData] = useState([])

    useEffect(() => {
        axiosInstance.post(api.menuTreeData)
           .then(res => {
                setTreeData(res.data)
            })
        loadData()
    }, [])
    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <div>
                    <Space>
                        <a  onClick={() => handleEdit(record)}>编辑</a>
                        <a onClick={() => handleDelete(record.id)}>删除</a>
                    </Space>
                </div>
            )
        }
    ]

    const loadData=(newPage?: number, searchParams = {} as any)=>{
        setTableLoading(true)
        const params = {
            page: newPage || page,
            pageSize: size,
            ...searchParams
        }
        axiosInstance.post(api.queryAuthority, params)
            .then((res: any)=>{
                if(res.code===0){
                    setData(res.data.result)
                    setTotal(res.data.total)
                }else {
                    message.error(res.message)
                }
            })
            .finally(()=>{
                setTableLoading(false)
            })
    }
    const pageChange = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        loadData(page)
    }

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }





    const handleAdd = () => {
        setMode('add')
        setVisible(true)
    }

    const handleEdit = (record) => {
        setMode('edit')
        setEditId(record.id)
        form.setFieldsValue({
            name: record.name,
            menu: record.menus
        })
        setVisible(true)
    }

    const handleDelete = (id) => {
        axiosInstance.post(api.deleteAuthority, {id})
            .then((res: any) => {
                if (res.code === 0) {
                    message.success('删除成功')
                    loadData()
                } else {
                    message.error('删除失败')
                }
            })
    }

    const submitForm = () => {
        form.validateFields().then(values => {
            console.log(values)
            if (mode === 'add') {
               axiosInstance.post(api.addAuthority, values)
                   .then((res: any)=>{
                       if(res.code===0){
                           message.success('添加成功')
                           setVisible(false)
                           loadData()
                       }else{
                           message.error('添加失败')
                       }
                   })
            } else {
                axiosInstance.post(api.updateAuthority, {id: editId, ...values})
                    .then((res: any)=>{
                        if(res.code===0){
                            message.success('修改成功')
                            setVisible(false)
                            loadData()
                        }else{
                            message.error('修改失败')
                        }
                    })
            }
        })
    }

    return (
        <div className="authority-page">
           <div className='tools'>
               <Button type='primary' onClick={handleAdd}>添加身份</Button>
           </div>
            <div className='table'>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={paginationSetting}
                    loading={tableLoading}
                ></Table>
            </div>
            <Modal
                open={visible}
                title={mode === 'add' ? '添加身份' : '编辑身份'}
                onCancel={() => setVisible(false)}
                onOk={submitForm}
            >
                <Form form={form}>
                    <Form.Item
                        label='名称'
                        name='name'
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='菜单'
                        name='menu'
                        rules={[{ required: true, message: '请选择菜单' }]}
                    >
                        <TreeSelect
                            treeData={treeData}
                            treeCheckable={true}
                            showCheckedStrategy="SHOW_PARENT"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default AuthorityPage