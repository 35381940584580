import React from "react";
import { Button, Space, Form, Select, Input, Table, Image, Modal, message, Popover, Timeline, Empty } from 'antd';
import axiosInstance from '../../axios/axios.ts';
import api from '../../axios/api.ts';
import { useNavigate } from 'react-router-dom';

import './orderList.scss'
import {Simulate} from "react-dom/test-utils";
import loadedData = Simulate.loadedData;

interface PageDto {
    page?: number;
    size?: number;
    params?: {
        id?: string | null;
        status?: string | null;
        company_name?: string | null;
    }
}

const OrderList: React.FC = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [bankForm] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [page, setPage] = React.useState<number>(1);
    const [size, setSize] = React.useState<number>(10);
    const [total, setTotal] = React.useState<number>(0);
    const [tableLoading, setTableLoading] = React.useState<boolean>(false);
    const [id, setId] = React.useState<any>(null)
    const [company_name, setCompany_name] = React.useState<any>(null)
    const [status, setStatus] = React.useState<any>(null)
    const [visible, setVisible] = React.useState(false);
    const [record, setRecord] = React.useState<any>({})
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        loadDate(1, {})
    }, [])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const onSearch = (values) => {
        setPage(1);
        loadDate(1, values);
        setId(values.id);
        setStatus(values.status);
        setCompany_name(values.company_name);
    }

    const resetSearch = () => {
        form.resetFields();
        setPage(1);
        const resetSearchParams = {
            id: null,
            company_name: null,
            status: null
        };
        resetLoad()
        setId(null);
        setStatus(null);
        setCompany_name(null);
    }

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        loadDate(page)
    }

    const payBill = (record) => {
        setRecord(record)
        setVisible(true)
    }

    const resetLoad=()=>{
        setTableLoading(true);
        axiosInstance.post(api.getOrderList, {page: 1,size: size, params: {}})
            .then(res=>{
                res.data.result.forEach(item=>{
                    item.key = item.id;
                })
                setTableData(res.data.result)
                setTotal(res.data.total)
            })
            .finally(()=>{
                setTableLoading(false)
            })
    }

    const loadDate = (newPage?: number, searchParams = {} as any) => {
        setTableLoading(true)
        const pageDto: PageDto = {
            page: newPage || page,
            size: size,
            params: {
                id: searchParams?.id ? searchParams?.id : id,
                company_name: searchParams?.company_name || company_name,
                status: searchParams?.status ? searchParams?.status : status
            }
        }
        axiosInstance.post(api.getOrderList, pageDto)
            .then(res => {
                res.data.result.forEach(item => {
                    item.key = item.id;
                })
                setTableData(res.data.result)
                setTotal(res.data.total)
            })
            .finally(() => {
                setTableLoading(false)
            })
    }

    const gotoEdit = (id) => {
        navigate(`/orderlist/edit?id=${id}`)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const submit = () => {
        bankForm.validateFields()
            .then(res => {
                setLoading(true);
                return axiosInstance.post(api.payOrderByAdmin, { ...res, id: record.id })
            })
            .then((res: any) => {
                if (res.code === 0) {
                    message.success("操作成功");
                    loadDate();
                    setVisible(false);
                } else {
                    message.error(res.msg);
                }
            })
            .catch(err => {
                console.log("err", err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const deleteOrders=()=>{
        console.log(selectedRowKeys)
        axiosInstance.post(api.deleteorder, {ids: selectedRowKeys})
            .then(()=>{
                loadDate()
            })
    }

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "实付金额",
            dataIndex: "pay_actual",
            key: "pay_actual"
        },
        {
            title: "下单时间",
            dataIndex: "create_time",
            key: "create_time",
            render: (text)=>{return<span>{new Date(text).toLocaleString()}</span>}
        },
        {
            title: "公司名称",
            key: "company",
            render: (_,record)=>{
                return <span>{record?.user?.company_name}</span>
            }
        },
        {
            title: "收货人",
            key: "receiver",
            render: (_, record) => {
                return (
                    <div>
                        <div>{record.receive_name}</div>
                        <div>{record.receive_phone}</div>
                    </div>
                )
            }
        },
        {
            title: "订单状态",
            dataIndex: "status",
            key: "status",
            render: (text: string) => {
                if (text == "FOR_PAY") {
                    return <span>待支付</span>
                } else if (text == "FOR_SEND") {
                    return <span>待发货</span>
                } else if (text == "SENDING") {
                    return <span>配送中</span>
                } else if (text == "RECEIVED") {
                    return <span>已收货</span>
                } else if (text == "COMPLETED") {
                    return <span>已完成</span>
                } else if (text == "CANCEL") {
                    return <span>已取消</span>
                } else if (text == "REFUND") {
                    return <span>已退款</span>
                }

            }
        },
        {
            title: "配送方式",
            dataIndex: "send_id",
            key: "send_id",
            align: "center",
            render: (_, record) => {
                const { logistic } = record;
                const data = logistic?.data?.map((item) => ({
                children: (
                    <Space direction="vertical">
                        <span>{item.time}</span>
                        <span>{item.context}</span>
                    </Space>
                ),
                }));
        
                return (
                <Popover
                    placement="right"
                    overlayClassName="logistic-popover"
                    content={
                        data?.length ? (
                            <Timeline items={data} className="logistic-line" />
                        ) : (
                            <Empty description='暂无物流信息' />
                        )
                    }
                >
                    <Space direction="vertical">
                        <a>{record.send_company?.name}</a>
                        <a className="logistic-word-break">{record.send_id}</a>
                    </Space>
                </Popover>
                );
            },
        },
        {
            title: "操作",
            key: "action",
            render: (_, record) => {
                return (
                    <Space>
                        <a onClick={() => gotoEdit(record.id)}>编辑</a>
                        {
                            record.status == "FOR_PAY" &&
                            <a onClick={() => payBill(record)}>支付</a>
                        }
                    </Space>
                )
            }
        }
    ]

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }

    const exportOrder=()=>{
        axiosInstance.post(api.exportOrder,{},{responseType: 'blob'})
            .then((response:any)=>{
                const url = window.URL.createObjectURL(new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // 明确文件类型
                }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'order.xlsx'); // 为下载的文件命名
                document.body.appendChild(link);
                link.click(); // 触发下载
                document.body.removeChild(link); // 下载后移除元素
                window.URL.revokeObjectURL(url); // 清理创建的URL对象，避免内存泄漏
            })
    }

    const completeOrder=()=>{
         axiosInstance.post(api.completeOrder)
        .then(()=>{
            loadDate()
        })
    }

    return (
        <div className="order-list">
            <div className='tools'>
                <Space size="middle">
                    <Button type="primary" onClick={exportOrder}>批量下载</Button>
                    <Button danger disabled={selectedRowKeys.length===0} onClick={deleteOrders}>批量删除</Button>
                    <Button type="primary" onClick={completeOrder}>完成订单</Button>
                </Space>
            </div>
            <div className='tools search'>
                <Form name="search" form={form} style={{ maxWidth: "100%" }} onFinish={onSearch}>
                    <Space>
                        <Form.Item name="id" label="订单ID" initialValue={null}><Input /></Form.Item>
                        <Form.Item name='company_name' label={"公司名称"} initialValue={null}><Input/></Form.Item>
                        <Form.Item name="status" label="订单状态" initialValue={null}>
                            <Select style={{ minWidth: "150px" }}>
                                <Select.Option value={null}>全部</Select.Option>
                                <Select.Option value={"FOR_PAY"}>待付款</Select.Option>
                                <Select.Option value={"FOR_SEND"}>待发货</Select.Option>
                                <Select.Option value={"SENDING"}>待收货</Select.Option>
                                <Select.Option value={"RECEIVED"}>已收货</Select.Option>
                                <Select.Option value={"CANCEL"}>已取消</Select.Option>
                                <Select.Option value={"COMPLETE"}>完成</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">查询</Button>
                                <Button onClick={resetSearch}>重置</Button>
                            </Space>
                        </Form.Item>
                    </Space>
                </Form>
            </div>
            <div className='table'>
                <Table 
                    rowSelection={rowSelection} 
                    columns={columns}
                    dataSource={tableData} 
                    pagination={paginationSetting} 
                    scroll={{ y: "800" }} 
                    loading={tableLoading}
                    expandable={{
                        expandedRowRender: (record =>{
                            return record?.skus.map((item, index)=>{
                                 return(
                                     <div className="order-sku-item" key={index}>
                                         <div className="name">{item.product.name }</div>
                                         <Image style={{width:"80px"}} src={item?.product?.pic_url[0]}/>
                                     </div>
                                 )
                            })
                        }),
                        rowExpandable: (record) =>record.skus.length!==0
                    }}

                />
            </div>
            <Modal
                title="对公转账"
                open={visible}
                closable={false}
                maskClosable={false}
                width={800}
                onOk={submit}
                onCancel={() => { bankForm.resetFields(); setVisible(false) }}
                confirmLoading={loading}
            >
                <div className="content">
                    <div className="item">
                        <div className="left">
                            <div className="label">订单号</div>
                            <div className="value">{record?.id}</div>
                        </div>
                        <div className="right">
                            <div className="label">下单日期</div>
                            <div className="value">{new Date(record?.create_time).toLocaleString()}</div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="left">
                            <div className="label">订单金额</div>
                            <div className="value" style={{color: "red", fontWeight: "bold"}}>{record?.pay_actual}</div>
                        </div>
                        <div className="right">
                            <div className="label">支付方式</div>
                            <div className="value">{record?.pay_method || "-"}</div>
                        </div>
                    </div>
                    <Form layout="vertical" form={bankForm} style={{ marginTop: "30px" }}>
                        <Form.Item name="receive_bank" label="收款银行" rules={[{ required: true, message: "请填写收款银行" }]}>
                            <Input placeholder="请填写收款银行" />
                        </Form.Item>
                        <Form.Item name="receive_account" label="收款账号" rules={[{ required: true, message: "请填写收款账号" }]}>
                            <Input placeholder="请填写收款账号" />
                        </Form.Item>
                        <Form.Item name="payer" label="付款人" rules={[{ required: true, message: "请填写付款人" }]}>
                            <Input placeholder="请填写付款人" />
                        </Form.Item>
                        <Form.Item name="receive_desc" label="收款单备注">
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default OrderList;
