import React from 'react';
import { Button, Space, Table, message, Upload, Modal, Popconfirm, Form, Input, Select } from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import type { UploadFile, UploadProps, RcFile } from 'antd/es/upload/interface';
import axiosInstance from '../../axios/axios.ts';

import api from '../../axios/api.ts';
import './skuList.scss'
interface PageDto {
    page?: number;
    size?: number;
    params?: {
        name?: string | null;
        sku_id?: string | null;
        published?: boolean | null;
        type_id?: string | null;
    }
}
const { Dragger } = Upload;


const SkuList = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [uploadOpen, setUpLoadOpen] = useState<boolean>(false);
    const [typeOptions, setTypeOptions] = useState<any[]>([]);
    const [searchValues, setSearchValues] = useState<any>({})
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    useEffect(() => {
        console.log("page", page)
        loadDate()
    }, [page, size])

    useEffect(() => {
        const page = queryParams.get("page");
        const delay = setTimeout(() => {
            setPage(Number(page) || 1);
            clearTimeout(delay);
        }, 1);
        axiosInstance.post(api.getSelectOptions)
            .then(res=>{
                res.data.unshift({value: null, label: '全部'})
                setTypeOptions(res.data)
            })
    }, [])

    const loadDate = (newPage?: number, searchParams = {} as any) => {
        setTableLoading(true)
        const pageDto: PageDto = {
            page: newPage || page,
            size: size,
            params: {
                name: searchParams?.name || searchValues?.name || null,
                sku_id: searchParams?.sku_id || searchValues?.sku_id || null,
                published: searchParams?.published === null ? null : searchParams?.published || searchValues?.published,
                type_id: searchParams?.type_id || searchValues?.type_id || null,
            }
        }
        axiosInstance.post(api.getProductList, pageDto)
            .then(res => {
                res.data.result.forEach(item => {
                    item.key = item.id;
                })
                setTableData(res.data.result)
                setTotal(res.data.total)
            })
            .finally(() => {
                setTableLoading(false)
            })
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const onDeleteAll = () => {
        if (hasSelected) {
            axiosInstance.post(api.deleteProduct, {
                id: selectedRowKeys
            }).then(res => {
                if ((res as any).code === 0) {
                    message.success('删除成功');
                    loadDate()
                }
            })
        } else {
            message.warning('请选择要删除的商品')
        }
    }

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setSize(size);
    }

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }

    // 编辑按钮
    const onCopy = (record) => {
        navigate(`/skulist/add?id=${record.id}&mode=copy`);
    }

    // 编辑按钮
    const onEdit = (record) => {
        navigate(`/skulist/add?id=${record.id}&mode=edit&page=${page}`);
    }

    // 删除按钮
    const onDelete = (record) => {
        axiosInstance.post(api.deleteProduct, {
            id: record.id
        }
        ).then(res => {
            if ((res as any).code === 0) {
                message.success('删除成功');
                setTableData((tableData as any).filter(item => item.id !== record.id));
            }
        })
    }

    const resetSearch = () => {
        form.resetFields();
        setPage(1);
        loadDate(1)
    }

    const onSearch = (values) => {
        setPage(1);
        loadDate(1, values);
        setSearchValues(values)
    }

    const columns = [
        {
            title: "商品名称",
            dataIndex: "name",
            key: "name",
            width: 200,
        },
        {
            title: "上架状态",
            dataIndex: "published",
            key: "published",
            render: (text) => {
                return (
                    <div className='status'>
                        <div className={text ? "published" : "unpublished"}></div>
                        <span>{text ? '上架' : '未上架'}</span>
                    </div>
                )
            }
        },
        {
            title: "销售价",
            dataIndex: "price",
            key: "price",
            render: (text) => {
                return <span>￥ {Number(text).toFixed(2)}</span>
            }
        }, {
            title: "市场价",
            dataIndex: "mark_price",
            key: "mark_price",
            render: (text) => {
                return <span>￥ {Number(text).toFixed(2)}</span>
            }
        },
        {
            title: "商品ID",
            dataIndex: "sku_id",
            key: "sku_id",
        },
        {
            title: "编辑",
            dataIndex: "edit",
            key: "edit",
            render: (text, record) => (
                <Space>
                    <a onClick={() => onCopy(record)}>复制</a>
                    <a onClick={() => onEdit(record)}>编辑</a>
                    <Popconfirm title="确认删除？" okText="确认" cancelText="取消" onConfirm={() => onDelete(record)}>
                        <a style={{ color: "#ff0000" }}>删除</a>
                    </Popconfirm>
                </Space>
            )
        },
    ];

    const props: UploadProps = {
        accept: ".xlsx, .xls, .csv",
        name: 'excel',
        multiple: false,
        action: api.addProductBath,
        headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} 上传成功.`);
                setUpLoadOpen(false);
                loadDate();

            } else if (status === 'error') {
                message.error(`${info.file.name} 上传失败.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <div className="sku-list">
            <div className='tools'>
                <Space size="large">
                    <Button type='primary' onClick={() => navigate(`/skulist/add?mode=add&total=${total}`)}>添加商品</Button>
                    <Button onClick={() => setUpLoadOpen(true)}>批量导入</Button>
                    <Button>批量下架</Button>
                    <Button danger onClick={onDeleteAll}>批量删除</Button>
                </Space>
            </div>
            <div className='tools search'>
                <Form name="search" form={form} style={{ maxWidth: "100%" }} onFinish={onSearch}>
                    <Space>
                        <Form.Item name="name" label="商品名称" initialValue={null}><Input /></Form.Item>
                        <Form.Item name="sku_id" label="商品ID" initialValue={null}><Input /></Form.Item>
                        <Form.Item name="published" label="上架状态" initialValue={null}>
                            <Select style={{ minWidth: "150px" }}>
                                <Select.Option value={null}>全部</Select.Option>
                                <Select.Option value={true}>上架</Select.Option>
                                <Select.Option value={false}>未上架</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="type_id" label="商品分类" initialValue={null}>
                            <Select options={typeOptions} style={{ minWidth: "150px" }}></Select>
                        </Form.Item>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">查询</Button>
                                <Button onClick={resetSearch}>重置</Button>
                            </Space>
                        </Form.Item>
                    </Space>
                </Form>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={paginationSetting} loading={tableLoading} />
            </div>
            <Modal
                open={uploadOpen}
                footer={null}
                title="批量上传"
                onCancel={() => setUpLoadOpen(false)}
                destroyOnClose
            >
                <div className='container'>
                    <div className='upload'>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">点击或者拖拽文件上传</p>
                        </Dragger>
                    </div>
                    <div className='desc'>最大上传条数不超过100条，批量上传商品默认未上架</div>
                    <a className='download' href='https://mengchong-1322498485.cos.ap-shanghai.myqcloud.com/product.xlsx'>模板下载</a>
                </div>
            </Modal>
        </div>
    )
}

export default SkuList;