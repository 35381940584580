const config = {
    development:{
        baseUrl: "https://mzegg.com/api"
        // baseUrl: "http://127.0.0.1:3000/api"
        // baseUrl: "https://cheerful-radically-quetzal.ngrok-free.app/api"
    },
    production: {
        baseUrl: "https://mzegg.com/api"
    }
}

const environment = process.env.NODE_ENV || 'development';

export default config[environment as keyof typeof config];