import React, { useEffect } from "react";
import { Form, Input, Select, Radio, Button, Space, message } from 'antd'
import "./memberAdd.scss"; //引入样式
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";

const { TextArea } = Input;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const MemberAdd: React.FC = () => {
    const [form] = Form.useForm();

    // 自定义确认密码校验规则
    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('user_password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('两次密码不相同'));
        },
    });

    // 保存
    const onSave = () => {
        form.validateFields()
            .then(
                (values) => {
                    console.log(values);
                    const params = {
                        user_phone: values.user_phone,
                        user_password: values.user_password,
                        user_level: values.user_level,
                        is_active: values.is_active,
                        user_nickname: values.user_nickname,
                        email: values.email,
                        company_name: values.company_name,
                    }
                    axiosInstance.post(api.addUser, params)
                        .then((res: any) => {
                            if(res.code===0){
                                message.success("创建成功");
                            }else{
                                message.error(res.msg);
                            }
                        })
                }

            )
            .catch(err => { })
    }

    // 取消
    const onCancel = () => {

    }

    const memberOptions = [
        {
            label: "注册经销",
            value: 1
        },
        {
            label: "授权经销",
            value: 2
        },
        {
            label: "VIP经销",
            value: 3
        }
    ]


    return (
        <div className="member-add">
            <div className="form">
                <Form
                    {...layout}
                    layout={"horizontal"}
                    style={{ maxWidth: 600 }}
                    form={form}
                    labelAlign="right"
                >
                    <Form.Item label="登录名(手机)" name="user_phone" rules={[{ required: true, message: '请填写手机号', pattern: /^(?:(?:\+|00)86)?1\d{10}$/ }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="密码" name="user_password" rules={[{ required: true, message: '请填写密码' }]}>
                        <Input.Password></Input.Password>
                    </Form.Item>
                    <Form.Item label="确认密码" name="confirm_pwd" rules={[{ required: true, message: '请确认密码' }, validateConfirmPassword]}>
                        <Input.Password></Input.Password>
                    </Form.Item>
                    <Form.Item label="会员等级" name="user_level">
                        <Select options={memberOptions }></Select>
                    </Form.Item>
                    <Form.Item label="是否激活" name="is_active">
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="您的姓名" name="user_nickname" rules={[{ required: true, message: '请填写姓名' }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item
                        label="电子邮件"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: '请填写正确电子邮箱',
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="公司名称(与营业执照一致)" name="company_name" rules={[{ required: true, message: '请填写信息' }]}>
                        <TextArea></TextArea>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Space>
                            <Button type="primary" htmlType="submit" onClick={onSave}>
                                保存
                            </Button>
                            <Button htmlType="reset" onClick={onCancel}>取消</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default MemberAdd;
