/*
 * @Author: liyunlong 2581235724@qq.com
 * @Date: 2023-12-21 14:06:19
 * @LastEditors: liyunlong 2581235724@qq.com
 * @LastEditTime: 2023-12-25 21:24:31
 * @FilePath: /mc/mc_management/src/router.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "./pages/login/login";
import Home from './pages/home/home'
import SkuList from "./pages/skuList/skuList.tsx";
import SkuAdd from "./pages/skuAdd/skuAdd.tsx";
import SignUp from './pages/signUp/signUp.tsx';
import PropList from "./pages/propList/propList.tsx";
import TypeList from "./pages/typeList/typeList.tsx";
import SpecList from "./pages/spec/specList.tsx"
import MemberAdd from "./pages/memberAdd/memberAdd.tsx";
import MemberEdit from "./pages/memberEdit/memberEdit.tsx";
import MemberList from "./pages/memberList/memberList.tsx";
import OrderList from "./pages/orderList/orderList.tsx";
import OrderListForSend from "./pages/orderForSend/orderListForSend.tsx";
import OrderEdit from "./pages/orderEdit/orderEdit.tsx";
import PageIndex from "./pages/pageIndex/pageIndex.tsx";
import AreaManage from "./pages/areaManage/areaManage.tsx";
import ShippingAddress from "./pages/shippingAddress/shippingAddress.tsx";
import ShippingCompany from "./pages/shippingCompany/shippingCompany.tsx";
import ShippingType from "./pages/shippingType/shippingType.tsx";
import CouponList from "./pages/couponList/couponList.tsx";
import CouponSend from "./pages/couponSend/couponSend.tsx";
import Marketing from "./pages/marketing/marketing.tsx";
import Message from "./pages/message/message.tsx";
import MessageAdd from "./pages/messageAdd/messageAdd.tsx";
import PrizePool from "./pages/prizePool/index.tsx";
import PrizePoolDetail from "./pages/prizePoolDetail/index.tsx";
import ActivityList from "./pages/activityList/index.tsx";
import ActivityDetail from "./pages/activeDetail/index.tsx";
import PrizeList from "./pages/prizeList/index.tsx";
import AuthorityPage from "./pages/authority/index.tsx";
import AdminSettingPage from "./pages/adminSetting/index.tsx";
import ReceiptList from "./pages/receiptList/index.tsx";

const getToken = () => {
    return localStorage.getItem("jwtToken")
}

function AuthRouter({ children }) {
    // 获取token
    const token = getToken()
    // 判断token是否存在
    if (token !== null) {
        return <>{children}</>
    } else {
        return <Navigate to={'/login'}></Navigate>
    }
}

//登录拦截
const router = createBrowserRouter([
    {
        path: '/',
        element: <AuthRouter><Home /></AuthRouter>,
        children: [
            { path: '/skulist', element: <AuthRouter><SkuList /></AuthRouter>, },
            { path: "/skulist/add", element: <AuthRouter><SkuAdd /></AuthRouter> },
            { path: "/proplist", element: <AuthRouter><PropList /></AuthRouter> },
            { path: "/typelist", element: <AuthRouter><TypeList /></AuthRouter> },
            { path: "/speclist", element: <AuthRouter><SpecList /></AuthRouter> },
            { path: "/memberadd", element: <AuthRouter><MemberAdd /></AuthRouter> },
            { path: "/memberlist", element: <AuthRouter><MemberList /></AuthRouter> },
            { path: "memberlist/edit", element: <AuthRouter><MemberEdit /></AuthRouter> },
            { path: "/orderlist", element: <AuthRouter><OrderList /></AuthRouter> },
            { path: "/orderlist/edit", element: <AuthRouter><OrderEdit /></AuthRouter> },
            { path: "/forsendlist", element: <AuthRouter><OrderListForSend /></AuthRouter> },
            { path: "/pageindex", element: <AuthRouter><PageIndex /></AuthRouter> },
            { path: "/areamanage", element: <AuthRouter><AreaManage /></AuthRouter> },
            { path: "/shippingaddress", element: <AuthRouter><ShippingAddress /></AuthRouter> },
            { path: "/shippingcompany", element: <AuthRouter><ShippingCompany /></AuthRouter> },
            { path: "/shippingtype", element: <AuthRouter><ShippingType /></AuthRouter> },
            { path: "/coupon", element: <AuthRouter><CouponList /></AuthRouter> },
            { path: "/coupon/send", element: <AuthRouter><CouponSend/></AuthRouter>},
            { path: "/marketing", element: <AuthRouter><Marketing /></AuthRouter> },
            { path: "/message", element: <AuthRouter><Message /></AuthRouter> },
            { path: "/message/add", element: <AuthRouter><MessageAdd /></AuthRouter> },
            {path:"/prizepool", element:<AuthRouter><PrizePool/></AuthRouter>},
            {path:"/prizepool/detail", element:<AuthRouter><PrizePoolDetail/></AuthRouter>},
            {path: "/activity", element: <AuthRouter><ActivityList /></AuthRouter>},
            {path: "/activity/detail", element: <AuthRouter><ActivityDetail /></AuthRouter>},
            {path: "/prizelist", element: <AuthRouter><PrizeList /></AuthRouter>},
            {path: "/authority", element: <AuthRouter><AuthorityPage /></AuthRouter>},
            {path: "/setting", element: <AuthRouter><AdminSettingPage /></AuthRouter>},
            { path: "/receiptlist", element: <AuthRouter><ReceiptList /></AuthRouter> }
        ]

    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/signup',
        element: <SignUp />
    }
])

export default router;