import React, { useState } from "react";
import { Form, Upload } from "antd";
import type { UploadFile, UploadProps, RcFile } from 'antd/es/upload/interface';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import api from "../../axios/api.ts";
import "./pageIndex.scss";

const PageIndex: React.FC = () => {
    const page = "index";
    const [fileList1, setFileList1] = useState<UploadFile[]>([]);
    const [fileList2, setFileList2] = useState<UploadFile[]>([]);
    const [fileList3, setFileList3] = useState<UploadFile[]>([]);
    const [fileList4, setFileList4] = useState<UploadFile[]>([]);
    const [fileList5, setFileList5] = useState<UploadFile[]>([]);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [previewTitle, setPreviewTitle] = useState<string>('');

    const [form] = Form.useForm();
    const onChange1: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        console.log(newFileList)
        setFileList1(newFileList);
    };
    const onChange2: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        console.log(newFileList)
        setFileList2(newFileList);
    };
    const onChange3: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        console.log(newFileList)
        setFileList3(newFileList);
    };
    const onChange4: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        console.log(newFileList)
        setFileList4(newFileList);
    };
    const onChange5: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        console.log(newFileList)
        setFileList5(newFileList);
    };

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <div className="page-index">
            <div className="content">
                <div className="item">
                    <Form form={form}>
                        <Form.Item label="01" name="01">
                            <Upload
                                accept="png, jpg, jpeg, gif, webp"
                                action={api.uploadImg}
                                fileList={fileList1}
                                listType="picture-card"
                                onChange={onChange1}
                                onPreview={handlePreview}
                            >
                                {fileList1.length === 0 && uploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item label="02" name="02">
                            <Upload
                                accept="png, jpg, jpeg, gif, webp"
                                action={api.uploadImg}
                                fileList={fileList2}
                                listType="picture-card"
                                onChange={onChange2}
                                onPreview={handlePreview}
                            >
                                {fileList2.length === 0 && uploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item label="03" name="03">
                            <Upload
                                accept="png, jpg, jpeg, gif, webp"
                                action={api.uploadImg}
                                fileList={fileList3}
                                listType="picture-card"
                                onChange={onChange3}
                                onPreview={handlePreview}
                            >
                                {fileList3.length === 0 && uploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item label="04" name="04">
                            <Upload
                                accept="png, jpg, jpeg, gif, webp"
                                action={api.uploadImg}
                                fileList={fileList4}
                                listType="picture-card"
                                onChange={onChange4}
                                onPreview={handlePreview}
                            >
                                {fileList4.length === 0 && uploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item label="05" name="05">
                            <Upload
                                accept="png, jpg, jpeg, gif, webp"
                                action={api.uploadImg}
                                fileList={fileList5}
                                listType="picture-card"
                                onChange={onChange5}
                                onPreview={handlePreview}
                            >
                                {fileList5.length === 0 && uploadButton}
                            </Upload>
                        </Form.Item>
                    </Form>
                </div>
                <div className="item">
                    <div className="iphone">
                        <div className="render"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageIndex;