import React from "react";
import { Table, Space, Modal, Input, message, Select } from "antd";
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";
import { Link } from "react-router-dom";
import './orderListForSend.scss';

interface PageDto {
    page?: number;
    size?: number;
}

const OrderListForSend: React.FC = () => {

    const [tableData, setTableData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [tableLoading, setTableLoading] = React.useState(false);
    const [size, setSize] = React.useState(10);
    const [record, setRecord] = React.useState<any>();
    const [modalOpen, setModalOpen] = React.useState(false)
    const [sendId, setSendId] = React.useState('');
    const [submitLoading, setSubmitLoading] = React.useState(false)
    const [companyOption, setCompanyOption] = React.useState<any>([])
    const [companyId, setCompanyId] = React.useState('');

    React.useEffect(() => {
        loadDate();
        axiosInstance.post(api.getShippingCompanyOptions)
            .then(res=>{
                setCompanyOption(res.data)
            })
    }, [])

    const loadDate = (newPage?: number) => {
        setTableLoading(true)
        const pageDto: PageDto = {
            page: newPage || page,
            size: size,
        }
        axiosInstance.post(api.getOrderForsend, pageDto)
            .then(res => {
                setTotal(res.data.total);
                setTableData(res.data.result);
            })
            .finally(() => {
                setTableLoading(false)
            })
    }

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setSize(size);
    }

    const send = (record) => {
        console.log(record); // 打印当前行的数据
        setRecord(record);
        setModalOpen(true);
    }

    const handleOk = () => {
        if(sendId===""){
            message.error('请输入快递单号');
            return;
        }
        setSubmitLoading(true)
        axiosInstance.post(api.updateSendId, { orderId: record.id, sendId: sendId, companyId: companyId})
            .then(res => {
                console.log(res);
                message.success('操作成功');
                setModalOpen(false);
                loadDate();
            })
            .finally(() => {
                setSubmitLoading(false);
            })
    }

    const handleCancel = () => {
        setModalOpen(false);
    }

    const inputChange = (event) => {
        console.log(event.target.value);
        setSendId(event.target.value);
    }

    const selectChange = (val)=>{
        console.log(val);
        setCompanyId(val)
    }

    const columns = [
        {
            title: "订单号",
            render: (text, record) => {
                return (
                    <span>{record.id}</span>
                )
            }
        },
        {
            title: "公司名称",
            render: (text, record) => {
                return (
                    <span>{record.user.company_name}</span>
                )
            }
        },
        {
            title: "商品名称",
            render: (_, record) => {
                return record?.skus?.map((item,index)=>{
                    return <div key={index}>{item.product.name}</div>
                })
            }
        },
        {
            title: "操作",
            render: (_, record) => {
                return (
                    <Space>
                        <Link onClick={() => send(record)} to="">发货</Link>
                    </Space>
                )
            }
        }
    ]

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }

    return (
        <div className="forsend">
            <div className='table'>
                <Table columns={columns} dataSource={tableData} pagination={paginationSetting} loading={tableLoading} />
            </div>
            <Modal
                open={modalOpen}
                title="发货"
                maskClosable={false}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="确认发货"
                cancelText="取消"
                width={800}
                confirmLoading={submitLoading}
            >
                <div className="content">
                    <div className="table-content">
                        <div className="table-item">
                            <div className="item-label">订单号：</div>
                            <div className="item-value">{record?.id}</div>
                        </div>
                        <div className="table-item">
                            <div className="item-label">下单时间：</div>
                            <div className="item-value">{new Date(record?.create_time).toLocaleString()}</div>
                        </div>
                        <div className="table-item">
                            <div className="item-label">应付金额：</div>
                            <div className="item-value">￥{Number(record?.pay).toFixed(2)}</div>
                        </div>
                        <div className="table-item">
                            <div className="item-label">实付金额：</div>
                            <div className="item-value">￥{Number(record?.pay_actual).toFixed(2)}</div>
                        </div>
                        <div className="table-item">
                            <div className="item-label">支付时间：</div>
                            <div className="item-value">{record?.paid_time?new Date(record?.paid_time).toLocaleString(): "-"}</div>
                        </div>
                        <div className="table-item">
                            <div className="item-label">收货人：</div>
                            <div className="item-value">{record?.receive_name}</div>
                        </div>
                        <div className="table-item">
                            <div className="item-label">收货手机：</div>
                            <div className="item-value">{record?.receive_phone}</div>
                        </div>
                        <div className="table-item">
                            <div className="item-label">收货地址：</div>
                            <div className="item-value">{record?.receive_address}</div>
                        </div>
                        {
                            record?.skus?.map((item,index)=>{
                                return(
                                    <div key={index}>
                                        <div className="table-item">
                                            <div className="item-label">商品名称：</div>
                                            <div className="item-value">{item?.product?.name}</div>
                                        </div>
                                        <div className="table-item">
                                            <div className="item-label">重量：</div>
                                            <div className="item-value">{item?.product?.weight}kg</div>
                                        </div>
                                        <div className="table-item">
                                            <div className="item-label">体积（长*宽*高）：</div>
                                            <div className="item-value">{`${item?.product?.length}cm * ${item?.product?.width}cm * ${item?.product?.height}cm`}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="table-item">
                            <div className="item-label">配送方式：</div>
                            <div className="item-value"><Select value={companyId} style={{width: "200px"}} options={companyOption} onChange={selectChange}></Select></div>
                        </div>
                        <div className="table-item">
                            <div className="item-label">快递单号：</div>
                            <div className="item-value"><Input className="input" onChange={inputChange} /></div>
                        </div>
                        
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default OrderListForSend;