import React, {useEffect, useState} from "react"
import './index.scss'
import {Button, Space, Table, Form, Modal, Input, InputNumber, Select, message} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import api from "../../axios/api.ts";
import { useNavigate } from 'react-router-dom';
import {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import axiosInstance from "../../axios/axios.ts";
const PrizePool: React.FC=()=>{
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [visible,setVisible] = useState<boolean>(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [prizeTypes, setPrizeTypes] = useState({0:"goods"});
    const [couponOptions, setCouponOptions] = useState<{label: string, value: string}[]>([])

    useEffect(() => {
        loadDate()
    }, [page, size])

    useEffect(() => {
        axiosInstance.post(api.couponOptions)
            .then((res)=>{
                if((res as any).code===0){
                    setCouponOptions(res.data)

                }else {
                    message.error("优惠券加载失败")
                }
            })
    }, []);

    const loadDate = (newPage?: number, searchParams = {} as any) => {
        setTableLoading(true)
        const pageDto = {
            page: newPage || page,
            size: size,
        }
        axiosInstance.post(api.queryPrizePool, pageDto)
            .then(res => {
                res.data.result.forEach(item => {
                    item.key = item.id;
                })
                setTableData(res.data.result)
                setTotal(res.data.total)
            })
            .finally(() => {
                setTableLoading(false)
            })
    }

    const handlePrizeTypeChange = (index, value) => {
        setPrizeTypes(prev => ({
            ...prev,
            [index]: value,
        }));
    };


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setSize(size);
    }

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const submit = ()=>{
        form.validateFields()
            .then(val=>{
                return axiosInstance.post(api.addPrizePool,val);
            })
            .then(()=>{
                setVisible(false)
                setPage(1);
                loadDate()
            })
    }

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }

    const gotoDetail=(id: string)=>{
        navigate(`/prizepool/detail?id=${id}`)
    }

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const deletePool=(id: string)=>{
        axiosInstance.post(api.deletePrizePool, {id})
            .then(res=>{
                if((res as any).code===0){
                    message.success("删除成功")
                    loadDate()
                }else {
                    message.error("删除失败")
                }
            })
    }

    const columns = [
        {
            title: "奖池名称",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "操作",
            key: "option",
            render:(_,record)=>{
                return <Space><a onClick={()=>gotoDetail(record.id)}>查看详情</a> <a onClick={()=>deletePool(record.id)}>删除</a></Space>
            }
        }
    ];
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return(
        <div className="product-pool">
            <div className='tools'>
                <Space size="large">
                    <Button type='primary' onClick={()=>setVisible(true)} >添加奖品池</Button>
                    <Button danger >批量删除</Button>
                </Space>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={paginationSetting} loading={tableLoading} />
            </div>
            <Modal
                open={visible}
                onCancel={()=>{form.resetFields(); setVisible(false)}}
                width={800}
                onOk={submit}
                closable={false}
            >
                <Form form={form}>
                    <Form.Item name="name" label="奖池名称">
                        <Input/>
                    </Form.Item>
                    <Form.List name="prize">
                        {(fields, { add, remove }) => (
                            <div>
                                {fields.map(({ key, name, ...restField },index) => (
                                    <div key={key} style={{ display: 'flex',alignItems: "center", marginBottom: 8, border: "solid 1px #e9e9e9", padding: "10px" }}>
                                        <Form.Item
                                            style={{ width: "30%" }}
                                            {...restField}
                                            name={[name,'type']}
                                            initialValue={'goods'}
                                        >
                                            <Select
                                                options={[{label: "奖品", value: "goods"},{label: "优惠券", value: "coupon"}]}
                                                defaultValue={'goods'}
                                                onChange={(value) => handlePrizeTypeChange(index, value)}
                                            ></Select>
                                        </Form.Item>

                                        {
                                            prizeTypes[index] === 'coupon' ?

                                                 <Form.Item
                                                    style={{ width: "50%",marginLeft:"20px" }}
                                                    {...restField}
                                                    name={[name,'coupon']}
                                                    rules={[{ required: true, message: 'Missing attribute name' }]}
                                                >
                                                    <Select placeholder={'请选择优惠券'} options={couponOptions}></Select>
                                                </Form.Item>:
                                                <>
                                                    <Form.Item
                                                        style={{ width: "50%" }}
                                                        {...restField}
                                                        name={[name, 'name']}
                                                        rules={[{ required: true, message: 'Missing attribute name' }]}
                                                    >
                                                        <Input placeholder="奖品名称" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ width: "50%",marginLeft:"20px" }}
                                                        {...restField}
                                                        name={[name, 'amount']}
                                                        rules={[{ required: true, message: 'Missing attribute name' }]}
                                                    >
                                                        <InputNumber style={{width:"120px"}} placeholder={"奖品数量"}/>
                                                    </Form.Item>

                                                </>
                                        }
                                        <Form.Item
                                            style={{ width: "50%" }}
                                            {...restField}
                                            name={[name, 'percent']}
                                            rules={[{ required: true, message: 'Missing attribute name' }]}
                                        >
                                            <InputNumber placeholder="中奖概率" addonAfter={"%"}/>
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'img']}
                                            rules={[{ required: true, message: 'Missing attribute name' }]}
                                        >
                                           <Input placeholder="图片地址"/>
                                        </Form.Item>
                                        <MinusCircleOutlined style={{color:"red"}} onClick={() => remove(name)} />
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        添加产品属性名称
                                    </Button>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List>
                </Form>
            </Modal>
        </div>
    )
}

export default PrizePool;