import React, {useEffect, useState} from "react"
import './index.scss'
import {Button, Space, Table, Form, Modal, Input, InputNumber, Upload, Image, Select, message} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import api from "../../axios/api.ts";
import {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import axiosInstance from "../../axios/axios.ts";
import { useSearchParams   } from 'react-router-dom';
const PrizePoolDetail: React.FC=()=>{
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [visible,setVisible] = useState<boolean>(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [currentRecord, setCurrentRecord] = useState<any>({});
    const [prizeType, setPrizeType] = useState<string>("goods");
    const [couponOptions, setCouponOptions] = useState<{label: string, value: string}[]>([])


    const id = searchParams.get('id');

    useEffect(() => {
        loadDate();
        axiosInstance.post(api.couponOptions)
            .then((res)=>{
                if((res as any).code===0){
                    setCouponOptions(res.data)

                }else {
                    message.error("优惠券加载失败")
                }
            })
    },[])

    const loadDate = () => {
        setTableLoading(true)

        axiosInstance.post(api.queryPrizeDetailByPoolId, {id})
            .then(res => {
                res.data.result.forEach(item => {
                    item.key = item.id;
                })
                setTableData(res.data.result)
            })
            .finally(() => {
                setTableLoading(false)
            })
    }


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const submit = ()=>{
        form.validateFields()
            .then(val=>{
                return axiosInstance.post(
                    currentRecord.id ? api.updatePrizePoo : api.addPrizePoo, 
                    {id: currentRecord.id || id, ...val}
                );
            })
            .then(()=>{
                setVisible(false)
                loadDate()
            })
    }

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const columns = [
        {
            title: "id",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "奖池名称",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "中奖概率（%）",
            dataIndex: "percent",
            key: "percent"
        },
        {
            title: "奖品数量",
            dataIndex: "quantity",
            key: "quantity"
        },
        {
            title: "奖品图片",
            dataIndex: "dataIndex",
            key: "imageUrl",
            render: (url)=>{
                return <Image style={{height:"80px"}} src={url}/>
            }
        },
        {
            title: "操作",
            key: "option",
            render:(_,record)=>{
                return <Space>
                    <a onClick={() => handleEdit(record)}>编辑</a>
                    <a onClick={() => handleDelete(record.id)}>删除</a>
                </Space>
            }
        }
    ];
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    // 编辑
    const handleEdit = (record: any) => {
        setCurrentRecord(record)
        setVisible(true)
        setPrizeType(record.type)
        form.setFieldsValue(record);
        form.setFieldValue('type', record.type)
        if(record.type==="coupon"){
            form.setFieldValue('coupon', record.coupon.id)
        }
    }

    // 删除
    const handleDelete = (id) => {
        axiosInstance.post(api.deletePrize, { id })
        .then(res => {
            loadDate()
        })
    }

    return(
        <div className="product-pool">
            <div className='tools'>
                <Space size="large">
                    <Button type='primary' onClick={() => {
                        setCurrentRecord({})
                        setVisible(true)
                        form.resetFields();
                    }} >添加奖品</Button>
                    {/* <Button danger >批量删除</Button> */}
                </Space>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={false} loading={tableLoading} />
            </div>
            <Modal
                open={visible}
                onCancel={()=>{form.resetFields(); setVisible(false)}}
                width={800}
                onOk={submit}
                closable={false}
            >
                <Form form={form}>
                    <Form.Item name="name" label="奖品名称">
                        <Input placeholder="奖品名称" />
                    </Form.Item>
                    <Form.Item name='type' label="奖品类型" initialValue='goods'>
                        <Select
                            onChange={(val)=>setPrizeType(val)}
                            options={[{label: "奖品", value: "goods"},{label: "优惠券", value: "coupon"}]}
                        ></Select>
                    </Form.Item>
                    {
                        prizeType==="goods"?
                            <Form.Item name="quantity" label="奖品数量">
                            <InputNumber style={{width:"120px"}} placeholder={"奖品数量"} />
                        </Form.Item>:
                            <Form.Item
                                name='coupon'
                                label='优惠券'
                                rules={[{required: true, message: "请选择优惠券"}]}
                            >
                                <Select options={couponOptions}></Select>
                            </Form.Item>
                    }
                    <Form.Item
                        name="percent"
                        label="中奖概率"
                        rules={[{required: true, message: "请填写中奖概率"}]}
                    >
                        <InputNumber placeholder="中奖概率" addonAfter={"%"}/>
                    </Form.Item>
                    <Form.Item
                        name="imageUrl"
                        label="图片地址"
                        rules={[{required: true, message: "请填写图片地址"}]}
                    >
                        <Input placeholder="图片地址"/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default PrizePoolDetail;