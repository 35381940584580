import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Form,
  Modal,
  Input,
  DatePicker,
  Select,
  Radio,
} from "antd";
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";
import "./index.scss";
import { Simulate } from "react-dom/test-utils";
import submit = Simulate.submit;
import { useNavigate } from "react-router-dom";
import moment from "moment";
import locale from 'antd/locale/zh_CN';

const ActivityList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [currentRecord, setCurrentRecord] = useState<any>({});

  useEffect(() => {
    axiosInstance.post(api.getPrizePoolOptions).then((res) => {
      setOptions(res.data);
    });
  }, []);

  const columns = [
    {
      title: "活动名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "开始时间",
      dataIndex: "start_time",
      key: "start_time",
      render: (text) => {
        return new Date(text).toLocaleDateString();
      },
    },
    {
      title: "结束时间",
      key: "end_time",
      dataIndex: "end_time",
      render: (text) => {
        return new Date(text).toLocaleDateString();
      },
    },
    {
      title: "操作",
      key: "option",
      render: (_, record) => {
        return (
          <Space>
            <a onClick={() => handleToPrizeMember(record.id)}>查看中奖名单</a>
            <a onClick={() => handleEdit(record)}>编辑</a>
            <a onClick={() => handleDelete([record.id])}>删除</a>
          </Space>
        );
      },
    },
  ];

  const pageChange = (page: number, size: number) => {
    setPage(page);
    setSize(size);
  };

  const paginationSetting = {
    current: page,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    total: total,
    onChange: pageChange,
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    loadDate();
  }, [page, size]);

  const submit = () => {
    form
      .validateFields()
      .then((value) => {
        console.log(value);
        return axiosInstance.post(
          currentRecord.id ? api.updateActivity : api.addActivity,
          {
            id: currentRecord.id,
            ...value,
          }
        );
      })
      .then(() => {
        setPage(1);
        setVisible(false);
        loadDate();
      });
  };

  const loadDate = (newPage?: number, searchParams = {} as any) => {
    setTableLoading(true);
    const pageDto = {
      page: newPage || page,
      size: size,
    };
    axiosInstance
      .post(api.queryActivity, pageDto)
      .then((res) => {
        res.data.result.forEach((item) => {
          item.key = item.id;
        });
        setTableData(res.data.result);
        setTotal(res.data.total);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // 查看中奖名单
  const handleToPrizeMember = (id) => {
    navigate(`/activity/detail?id=${id}`);
  };

  // 编辑
  const handleEdit = (record) => {
    setCurrentRecord(record);
    setVisible(true);
    const value = {
      ...record,
      start_time: moment(record.start_time),
      end_time: moment(record.end_time),
      pool_one: record.pool_one?.id,
      pool_two: record.pool_two?.id,
      pool_three: record.pool_three?.id,
    };
    form.setFieldsValue(value);
  };

  // 删除
  const handleDelete = (id) => {
    axiosInstance.post(api.deleteActivity, { id }).then((res) => {
      loadDate();
    });
  };

  return (
    <div className="activity-list">
      <div className="tools">
        <Space size="large">
          <Button
            type="primary"
            onClick={() => {
              setCurrentRecord({});
              setVisible(true);
              form.resetFields();
            }}
          >
            添加活动
          </Button>
          <Button danger onClick={() => handleDelete(selectedRowKeys)}>
            批量删除
          </Button>
        </Space>
      </div>
      <div className="table">
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          pagination={paginationSetting}
          loading={tableLoading}
        />
      </div>
      <Modal
        open={visible}
        onOk={submit}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        closable={false}
      >
        <Form form={form}>
          <Form.Item label={"活动名称"} name={"name"} required={true}>
            <Input />
          </Form.Item>
          <Form.Item label={"活动状态"} name={"status"} initialValue={true}>
            <Radio.Group>
              <Radio value={true}>启用</Radio>
              <Radio value={false}>禁用</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={"活动开始时间"} name={"start_time"} required={true}>
            <DatePicker showTime />
          </Form.Item>
          <Form.Item label={"活动结束时间"} name={"end_time"} required={true}>
            <DatePicker showTime />
          </Form.Item>
          <Form.Item label={"授权会员奖池"} name={"pool_one"} required={true}>
            <Select options={options} placeholder={"请选择奖池"}></Select>
          </Form.Item>
          <Form.Item label={"经销会员奖池"} name={"pool_two"} required={true}>
            <Select options={options} placeholder={"请选择奖池"}></Select>
          </Form.Item>
          <Form.Item label={"核心会员奖池"} name={"pool_three"} required={true}>
            <Select options={options} placeholder={"请选择奖池"}></Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ActivityList;
