import React from 'react'
import { Space, Button, Table, Modal, Form, message, Popconfirm } from 'antd'
import Link from "antd/es/typography/Link";
import AddSpec from '../../components/addSpec/addSpec.tsx';
import axiosInstance from '../../axios/axios.ts'
import api from '../../axios/api.ts';
import './specList.scss'

interface PageDto {
    page?: number;
    size?: number;
}

const SpecList: React.FC = () => {
    const [disable, setDisable] = React.useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [page, setPage] = React.useState<number>(1);
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [total, setTotal] = React.useState<number>(0);
    const [tableLoading, setTableLoading] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>("添加产品属性");
    const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
    const [specId, setSpecId] = React.useState<number | null>(null);
    const [editingRecord, setEditingRecord] = React.useState<any>(null);
    const [form] = Form.useForm();





    React.useEffect(() => {
        loadDate()
    }, [page, pageSize])

    React.useEffect(() => {
        if (selectedRowKeys.length === 0) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [selectedRowKeys])

    const loadDate = (newPage?: number) => {
        setTableLoading(true)
        const pageDto: PageDto = {
            page: newPage || page,
            size: pageSize
        }
        axiosInstance.post(api.querySpecByPage, pageDto)
            .then(res => {
                setTableData(res.data.result)
            })
            .finally(() => {
                setTableLoading(false)
            })

    }



    const columns = [
        {
            title: '规格名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '备注',
            dataIndex: 'desc',
            key: 'desc',
        },
        {
            title: '操作',
            render: (record) => {
                return (
                    <Space key={"item" + record.id}>
                        <Link onClick={() => handleEdit(record)}>编辑</Link>
                        <Popconfirm title="确认删除？" okText="确认" cancelText="取消" onConfirm={() => deleteSpec(record)}>
                            <Link>删除</Link>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ]


    const handleEdit = (record) => {

        axiosInstance.post(api.getSpecDetail, { id: record.id })
            .then(res => {
                console.log(res)
                const { data } = res;
                setSpecId(record.id);
                setTitle("编辑产品规格")
                setIsModalOpen(true);
                setEditingRecord(res.data)
            })
    }

    const deleteSpec = (record) => {
        console.log(record)
        const idList = [record.id];
        axiosInstance.post(api.deleteSpec, idList)
            .then(() => {
                message.success("删除成功", 3);
                loadDate();
            })
    }

    const deleteAllSpec = () => {
        axiosInstance.post(api.deleteSpec, selectedRowKeys)
            .then(() => {
                message.success("删除成功", 3);
                loadDate();
            })
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setPageSize(size);
    }

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }

    const createSpec = (values) => {
        const items: any = []
        if (values.spec_items !== undefined) {
            values.spec_items.forEach((item) => {
                items.push({
                    key: item.key,
                    value: item.value,
                    img: item.imageUrl
                })
            })
        }
        console.log("items", items)
        const params = {
            name: values.spec_name,
            desc: values.spec_desc,
            type: values.spec_type,
            items: items
        }
        axiosInstance.post(api.addSpec, params)
            .then(res => {
                message.success('添加成功');
                setIsModalOpen(false)
                setPage(1);
                loadDate();
            })
            .finally(() => {
                setSubmitLoading(false);
                form.resetFields();
            })

        console.log(params);
    }

    const updateSpec = (values) => { 
        const items: any = []
        if (values.spec_items !== undefined) {
            values.spec_items.forEach((item) => {
                items.push({
                    id: item.id,
                    key: item.key,
                    value: item.value,
                    img: item.imageUrl
                })
            })
        }
        const params = {
            id: values.spec_id,
            name: values.spec_name,
            desc: values.spec_desc,
            type: values.spec_type,
            items: items
        }

        axiosInstance.post(api.updateSpec, params)
        .then(res=>{
            console.log(res)
            message.success('修改成功');
            form.resetFields();
            setEditingRecord(null);
            setIsModalOpen(false);
            loadDate();
        })
        .finally(()=>{
            setSubmitLoading(false);
        })

    }

    const handleSubmit = () => {
        setSubmitLoading(true);
        form.validateFields()
            .then(values => {
                console.log(values);
                if (specId === null) {
                    //新建
                    createSpec(values);
                } else {
                    //更新
                    updateSpec(values);
                }
            })
            .catch(() => {

            })
    }


    const cancel = () => {
        setSubmitLoading(false);
        form.resetFields();
        setIsModalOpen(false)
    }

    return (
        <div className='spec-list'>
            <div className='tools'>
                <Space size="middle">
                    <Button type='primary' onClick={() => { setIsModalOpen(true); setTitle("添加产品规格") }}>添加属性</Button>
                    <Button danger disabled={disable} onClick={deleteAllSpec}>批量删除</Button>
                </Space>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={paginationSetting} scroll={{ y: "800" }} loading={tableLoading} />
            </div>
            <Modal
                open={isModalOpen}
                width={700}
                closable={false}
                title={title}
                onOk={handleSubmit}
                onCancel={cancel}
                okText="提交"
                confirmLoading={submitLoading}
                cancelText="取消">
                <AddSpec form={form} initialData={editingRecord} />
            </Modal>
        </div>
    )
}

export default SpecList;