import React, { useEffect, useState } from "react";
import { Button, Space, Table } from "antd";
import api from "../../axios/api.ts";
import axiosInstance from "../../axios/axios.ts";
import { useSearchParams } from "react-router-dom";

const PrizeMember: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);

  const id = searchParams.get("id");

  useEffect(() => {
    loadDate();
  }, [page, size]);

  const loadDate = (newPage?: number, searchParams = {} as any) => {
    setTableLoading(true);
    const pageDto = {
      id,
      page: newPage || page,
      size: size,
    };
    axiosInstance
      .post(api.getActivityResult, pageDto)
      .then((res: any) => {
        console.log(res);
        if (res.code === 0) {
          setTotal(res.data.total);
          setTableData(res.data.result);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getTreeResultExcel = () => {
    axiosInstance
      .post(api.downloadActivityResult, {}, { responseType: "blob" })
      .then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // 明确文件类型
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "result.xlsx"); // 为下载的文件命名
        document.body.appendChild(link);
        link.click(); // 触发下载
        document.body.removeChild(link); // 下载后移除元素
        window.URL.revokeObjectURL(url); // 清理创建的URL对象，避免内存泄漏
      });
  };

  const pageChange = (page: number, size: number) => {
    setPage(page);
    setSize(size);
  };

  const confirmPrize = (id) => {
    axiosInstance.post(api.confirmTreePrize, { id }).then(() => {
      console.log("success");
      loadDate();
    });
  };

  const columns = [
    {
      title: "奖品名称",
      render: (_, record) => {
        return record.prize.name;
      },
      key: "prizeName",
    },
    {
      title: "中奖人姓名",
      render: (_, record) => {
        return record.user.user_nickname;
      },
      key: "user_nickname",
    },
    {
      title: "中奖人手机号",
      render: (_, record) => {
        return record.user.user_phone;
      },
      key: "user_phone",
    },
    {
      title: "中奖人公司",
      render: (_, record) => {
        return record.user.company_name;
      },
      key: "company_name",
    },
    {
      title: "中奖时间",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (txt) => {
        return new Date(txt).toLocaleString();
      },
    },
    {
      title: "操作",
      dataIndex: "operate",
      key: "operate",
      render: (_, record) => {
        return (
          !record.is_used && (
            <a onClick={() => confirmPrize(record.id)}>确认领奖</a>
          )
        );
      },
    },
  ];

  const paginationSetting = {
    current: page,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    total: total,
    onChange: pageChange,
  };

  return (
    <div className={"prize-list"}>
      <div className="tools">
        <Space size="large">
          <Button type="primary" onClick={getTreeResultExcel} disabled>
            导出结果
          </Button>
        </Space>
      </div>
      <div className="table">
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={paginationSetting}
          loading={tableLoading}
        />
      </div>
    </div>
  );
};

export default PrizeMember;
