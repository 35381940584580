import { Button, Form, Input, message, Tabs } from 'antd';
import axios from 'axios';
import axiosInstance from '../../axios/axios.ts';
import api from '../../axios/api.ts';
import { Link, useNavigate } from 'react-router-dom'
import { useState, useRef, useEffect } from 'react';
import './login.css'

const Login = () => {
    const formRef = useRef(null);
    const [btnDisable, setBtnDisable] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        let interval;
        if (countdown > 0) {
            interval = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else {
            setBtnDisable(false);
        }
        return () => clearInterval(interval);
    }, [countdown]);
    const onFinish = (values) => {
        const { username, password } = values;
        axios.post(api.login, { u_name: username, u_password: password })
            .then(res => {
                if (res.data.code === 0) {
                    localStorage.setItem("jwtToken", res.data.data.token);
                    navigate("/skulist");
                } else {
                    messageApi.error(res.data.msg)
                }
            })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const getCode = () => {
        formRef.current.validateFields(["phone"])
            .then(phone => {
                console.log(phone)
                axiosInstance.post(api.sendCode, phone)
                    .then((res) => {
                        if (res.code === 0) {
                            messageApi.success("发送成功，请查收", 3);
                            setCountdown(60);
                            setBtnDisable(true);
                        } else {
                            messageApi.error(res.msg, 3);
                        }
                    })
            })
            .catch(() => {
                messageApi.error("请填写手机号")
            })
    }
    const phoneFinish = (values) => {
        axiosInstance.post(api.loginWithCode, values)
            .then(res => {
                if (res.code === 0) {
                    localStorage.setItem("jwtToken", res.data.token);
                    navigate("/skulist");
                } else {
                    messageApi.error(res.msg, 3);
                }
            })
    }

    const phoneFocus = () => {
        console.log("focus")
        formRef.current.setFields([
            {
                name: 'phone',
                errors: [],
            },
        ]);
    }
    const password = (
        <Form
            ref={formRef}
            name="basic"
            style={{
                maxWidth: 600,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: '请输入用户名',
                    },
                ]}
            >
                <Input size='large' className='input' placeholder='请输入用户名' />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: '请输入密码',
                    },
                ]}
            >
                <Input type='password' size='large' className='input' placeholder='请输入密码' />
            </Form.Item>
            <div className='btn-box'>
                <Button style={{ width: "100%" }} size='large' type="primary" htmlType="submit">
                    立即登录
                </Button>
                <Link className='link' to="/signup">员工注册</Link>
            </div>
        </Form>
    );
    const phone = (
        <Form
            ref={formRef}
            style={{
                maxWidth: 600,
            }}
            onFinish={phoneFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                name="phone"
                rules={[
                    {
                        required: true,
                        message: "请输入手机号"
                    },
                    {
                        pattern: /^1[3-9]\d{9}$/,
                        message: '请输入有效的手机号'
                    }
                ]}
                validateTrigger="onBlur"
            >
                <Input onFocus={phoneFocus} className='input' size='large' type='phone' placeholder='请输入手机号' />
            </Form.Item>
            <div className='code-container'>
                <Form.Item
                    className='code'
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: "请输入验证码"
                        }
                    ]}
                >
                    <Input className='input' size='large' maxLength={6} placeholder='请输入验证码' />
                </Form.Item>
                <Button onClick={getCode} disabled={btnDisable} size='large' type='primary' className='code-btn'>{countdown > 0 ? `${countdown}秒后重试` : '发送验证码'}</Button>
            </div>
            <div className='btn-box'>
                <Button style={{ width: "100%" }} size='large' type="primary" htmlType="submit">
                    立即登录
                </Button>
                <Link className='link' to="/signup">员工注册</Link>
            </div>
        </Form>
    )
    const items = [
        {
            key: 1,
            label: "账号密码登录",
            children: password
        },
        {
            key: 2,
            label: "验证码登录",
            children: phone
        },
        {
            key: 3,
            label: "二维码登录",
        }
    ]
    return (
        <div className='login'>
            <div className='header'>
                <span className='header-txt'>登录</span>
            </div>
            <div className='pic-box'>
                <div className='pic-txt'>欢迎登录</div>
                <div className='pic-txt'>盟虫闪店运营平台</div>
                <div className='img'></div>
            </div>
            {contextHolder}
            <Tabs className={'login-box'} items={items} defaultActiveKey='1' />  
            <div className="copyright-container" style={{  
                display: 'flex',  
                justifyContent: 'center', // 使得内部内容水平居中  
                alignItems: 'center', // 垂直居中（如果需要的话）  
                padding: '10px 0', // 可以根据需要添加内边距  
            }}>  
                <div className="copyright" style={{ display: 'flex', alignItems: 'center' }}>  
                    <img height={16} src="/copyright.png" alt="copyright" style={{ marginRight: '8px' }} />  
                    <a  
                        href="https://beian.miit.gov.cn/"  
                        rel="noreferrer"  
                        target="_blank"  
                        style={{ marginRight: '8px' }} // 添加右边距，避免与下一个链接太紧  
                    >  
                        沪ICP备2023033256号-2  
                    </a>  
                    <span>丨</span> {/* 使用span代替多余的空格和“丨”的单独链接 */}  
                    <a  
                        href="https://beian.mps.gov.cn/#/query/websearch?code=31011702889362"  
                        rel="noreferrer"  
                        target="_blank"  
                    >  
                        沪公网安备31011702889362号  
                    </a>  
                </div>  
            </div>  
        </div>  
    );  
};  
  
export default Login;

