import React, { useState, useEffect } from "react";
import { Button, Space, Table, Modal, Upload, message, Image, Form, Select, Input, Popconfirm } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../../axios/axios.ts';
import type { UploadProps } from 'antd/es/upload/interface';
import api from '../../axios/api.ts';
import "./memberList.scss"

interface PageDto {
    page?: number;
    size?: number;
    params?: {
        id?: string | null;
        company_name?: string | null;
        is_active?: boolean | null;
        user_phone: string | null;
        user_level: string | null;
    }
}

const { Dragger } = Upload;
const MemberList: React.FC = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [uploadOpen, setUpLoadOpen] = useState<boolean>(false);
    const [record, setRecord] = useState<any>();
    const [reviewOpen, setReviewOpen] = useState<boolean>(false);
    const [memberOne, setMemberOne] = useState<number>(0);
    const [memberTwo, setMemberTwo] = useState(0);
    const [memberThree, setMemberThree] = useState(0);

    useEffect(() => {
        loadDate()
    }, [page, size])



    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const countUser = ()=>{
        axiosInstance.post(api.countUser)
            .then((res: any)=>{
                if(res.code===0){
                    setMemberOne(res.data.memberOne);
                    setMemberTwo(res.data.memberTwo);
                    setMemberThree(res.data.memberThree);
                }
            })
    }

    const loadDate = (newPage?: number, searchParams = {} as any) => {
        countUser()
        setTableLoading(true)
        const pageDto: PageDto = {
            page: newPage || page,
            size: size,
            params: {
                id: searchParams?.id || null,
                company_name: searchParams?.company_name || form.getFieldValue("company_name"),
                is_active: searchParams?.is_active || form.getFieldValue("is_active"),
                user_phone: searchParams?.user_phone || form.getFieldValue("user_phone"),
                user_level: searchParams?.user_level || form.getFieldValue("user_level"),

            }
        }
        axiosInstance.post(api.getUserList, pageDto)
            .then(res => {
                res.data.result.forEach((item, index) => {
                    item.key = index;
                })
                setTableData(res.data.result)
                setTotal(res.data.total)
            })
            .finally(() => {
                setTableLoading(false)
            })

    }


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setSize(size);
    }

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }

    const review = (record) => {
        setRecord(record)
        setReviewOpen(true)
    }

    const reviewCancel = () => {
        setRecord({});
        setReviewOpen(false);
    }

    const reviewPass = () => {
        axiosInstance.post(api.reviewPass, { id: record.id })
            .then(() => {
                message.success('审核通过');
                loadDate();
            })
            .finally(() => {
                setReviewOpen(false);
                setRecord({});
            })
    }

    const onSearch = (values) => {
        setPage(1);
        loadDate(1, values);
    }

    const resetSearch = () => {
        form.resetFields();
        setPage(1);
        loadDate(1)
    }

    const handleDelete = (id) => {
        axiosInstance.post(api.deleteUser, { id })
            .then(res => {
                message.success('删除成功');
                loadDate();
            })
    }

    const setMain = (id) => {
        axiosInstance.post(api.setMainAccount, { id })
            .then(res => {
                message.success('更新成功');
                loadDate();
            })
    }

    const calcuatDate = (targert) => {
        // 创建特定日期的Date对象
        const specificDate = new Date(targert);

        // 创建当前日期的Date对象
        const currentDate = new Date();

        // 计算两个日期之间的毫秒差
        const differenceInMilliseconds = specificDate.getTime() - currentDate.getTime();

        // 将毫秒数转换为天数
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        return differenceInDays>0? differenceInDays : "-";
    }

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 100,
        },
        {
            title: "会员昵称",
            dataIndex: "user_nickname",
            key: "user_nickname",
            width: 200,
        },
        {
            title: "会员等级",
            key: "user_level",
            dataIndex: "user_level",
            render: (text) => {
                if (text === 1) {
                    return <span>注册经销</span>
                } else if (text === 2) {
                    return <span>授权经销</span>
                } else if (text === 3) {
                    return <span>VIP经销</span>
                }
            }
        },
        {
            title: "是否激活",
            dataIndex: "is_active",
            key: "is_active",
            render: (text) => {
                return (
                    <div className='status'>
                        <div className={text ? "published" : "unpublished"}></div>
                        <span>{text ? '激活' : '未激活'}</span>
                    </div>
                )
            }
        },
        {
            title: "手机号",
            dataIndex: "user_phone",
            key: "user_phone",
        }, {
            title: "公司名称",
            dataIndex: "company_name",
            key: "company_name",
        },
        {
            title: "积分",
            dataIndex: "score",
            key: "score",
        },
        {
            title: "是否为主账号",
            dataIndex: "main",
            key: "main",
            render: (text) => {
                return (<span>{text ? "是" : "否"}</span>)
            }
        },
        {
            title: "会员注册时间",
            dataIndex: "create_time",
            key: "create_time",
            render: (text)=>{
                return (<span>{new Date(text).toLocaleDateString()}</span>)
            }
        },
        {
            title: "会员到期时间",
            dataIndex: "member_end_time",
            key: "member_end_time",
            render: (text) => {
                return (text ? <span>{new Date(text).toLocaleDateString()}</span> : "-")
            }
        },
        {
            title: "会员剩余天数",
            key: "member_end_time",
            render: (_, record) => {
                return <span>{calcuatDate(record.member_end_time)}</span>
            }
        },
        {
            title: "操作",
            dataIndex: "operation",
            key: "operation",
            render: (text, record) => {
                return (
                    <Space>
                        <Popconfirm onConfirm={() => handleDelete(record.id)} title="警告" okType="danger" okText="删除" description="确定要删除吗" cancelText="取消">
                            <a style={{ color: "red" }}>删除</a>
                        </Popconfirm>
                        <Link to={`/memberlist/edit?id=${record.id}`}>编辑</Link>
                        {record.main ? "" : <a onClick={() => { setMain(record.id) }}>设为主账号</a>}
                        {!record.is_active ? <Link to={""} onClick={() => review(record)}>审核</Link> : ""}
                    </Space>
                )
            }
        }
    ];

    const props: UploadProps = {
        accept: ".xlsx, .xls, .csv",
        name: 'excel',
        multiple: false,
        action: api.addUserBatch,
        headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} 上传成功.`);
                setUpLoadOpen(false);
                loadDate();

            } else if (status === 'error') {
                message.error(`${info.file.name} 上传失败.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const exportMember=()=>{
        axiosInstance.post(api.exportUser,{},{responseType: "blob"})
            .then((response: any)=>{
                const url = window.URL.createObjectURL(new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // 明确文件类型
                }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'user.xlsx'); // 为下载的文件命名
                document.body.appendChild(link);
                link.click(); // 触发下载
                document.body.removeChild(link); // 下载后移除元素
                window.URL.revokeObjectURL(url); // 清理创建的URL对象，避免内存泄漏
            })
    }

    return (
        <div className="member-list">
            <div className='tools'>
                <Space size="large">
                    <Button type='primary' onClick={() => navigate(`/memberadd`)}>添加会员</Button>
                    <Button type='primary' onClick={exportMember}>批量导出</Button>
                    <Button onClick={() => setUpLoadOpen(true)}>批量导入</Button>
                    <div style={{fontSize: '20px'}}>会员总数：<span style={{color: 'red', fontWeight: 'bold'}}> {total}</span></div>
                    <div style={{fontSize: '20px'}}>注册会员：<span style={{color: 'red', fontWeight: 'bold'}}> {memberOne}</span></div>
                    <div style={{fontSize: '20px'}}>经销会员：<span style={{color: 'red', fontWeight: 'bold'}}> {memberTwo}</span></div>
                    <div style={{fontSize: '20px'}}>VIP会员：<span style={{color: 'red', fontWeight: 'bold'}}> {memberThree}</span></div>

                </Space>
            </div>
            <div className='tools search'>
                <Form name="search" form={form} style={{ maxWidth: "100%" }} onFinish={onSearch}>
                    <Space>
                        <Form.Item name="id" label="用户ID" initialValue={null}><Input /></Form.Item>
                        <Form.Item name="company_name" label="公司名称" initialValue={null}><Input /></Form.Item>
                        <Form.Item name="user_phone" label="手机号" initialValue={null}><Input /></Form.Item>
                        <Form.Item name="user_level" label="会员等级" initialValue={null}>
                            <Select style={{ minWidth: "150px" }}>
                                <Select.Option value={null}>全部</Select.Option>
                                <Select.Option value={1}>注册经销</Select.Option>
                                <Select.Option value={2}>授权经销</Select.Option>
                                <Select.Option value={3}>VIP经销</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="is_active" label="审核状态" initialValue={null}>
                            <Select style={{ minWidth: "150px" }}>
                                <Select.Option value={null}>全部</Select.Option>
                                <Select.Option value={true}>已审核</Select.Option>
                                <Select.Option value={false}>未审核</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">查询</Button>
                                <Button onClick={resetSearch}>重置</Button>
                            </Space>
                        </Form.Item>
                    </Space>
                </Form>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={paginationSetting} loading={tableLoading} />
            </div>
            <Modal
                open={uploadOpen}
                footer={null}
                title="批量上传"
                onCancel={() => setUpLoadOpen(false)}
                destroyOnClose
                width={800}
            >
                <div className='upload-container'>
                    <div className='upload'>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">点击或者拖拽文件上传</p>
                        </Dragger>
                    </div>
                    <div className='desc'>最大上传条数不超过100条，批量上传商品默认未上架</div>
                    <a className='download' href='https://mengchong-1322498485.cos.ap-shanghai.myqcloud.com/%E6%A8%A1%E7%89%88%E8%A1%A8%E6%A0%BC/add%20user.xlsx'>模板下载</a>
                </div>
            </Modal>
            <Modal
                width={800}
                open={reviewOpen}
                title="审核"
                onCancel={reviewCancel}
                onOk={reviewPass}
                maskClosable={false}
                cancelText="驳回"
                cancelButtonProps={{ type: 'default', danger: true }}
                destroyOnClose
                okText="通过"
            >
                <div className="info-list">
                    <div className="info-item">
                        <div className="info-label">会员昵称：</div>
                        <div className="info-value">{record?.user_nickname || "-"}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">邮箱：</div>
                        <div className="info-value">{record?.email || "-"}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">手机号：</div>
                        <div className="info-value">{record?.user_phone || "-"}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">公司名称：</div>
                        <div className="info-value">{record?.company_name || "-"}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">公司照片：</div>
                        <div className="info-value" style={{ color: "red" }}>
                            {record?.company_img &&
                                <Image
                                    width={100}
                                    src={record?.company_img}
                                    alt="公司照片"
                                />
                            }
                            {!record?.company_img && "未上传照片"}
                        </div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">会员等级：</div>
                        <div className="info-value">{record?.user_level || "-"}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">用户积分：</div>
                        <div className="info-value">{record?.score || "-"}</div>
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default MemberList;
