import React, { useEffect, useState } from "react";
import { Form, Input, Radio, Select, Button, message } from "antd";
import MyEditor from "../../components/editor/editor.tsx";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";
import DebounceSelect from "./components/debounceSelect.tsx";
import './messageAdd.scss'

interface UserValue {
    label: string;
    value: string;
}

const MessageAdd = () => {

    const [reciever, setReciever] = useState<any>("all")
    const [myHtml, setMyHtml] = useState<any>("")
    const [memberOptions, setMemberOptions] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [value, setValue] = useState<UserValue[]>([]);
    const [form] = Form.useForm();
    const navigate = useNavigate()

    useEffect(() => {
        axiosInstance.post(api.getUserOptions)
            .then(res=>{
                console.log(res)
                setMemberOptions(res.data)
            })
        }, [])

    const revieverChange = (e: any) => {
        setReciever(e.target.value)
    }

    const fetchUserList=async(username: string): Promise<UserValue[]>=> {
        return axiosInstance.post(api.fuzzySearchUser,{content: username})
            .then(res=>{
                return res.data.options
            })
    }

    const submit = () => {
        form.validateFields()
            .then(values => {
                const params: any={
                    ...values
                }
                if(values.member){
                    params.member = values.member.map(item=>item.value)
                }
                setLoading(true)
                axiosInstance.post(api.addMessage, {...params})
                .then(res=>{
                    message.success("发送成功");
                })
                .finally(()=>{
                    setLoading(false)
                })
            })
    }
    return (
        <div className="msg-add">
            <Form layout="vertical" form={form}>
                <div className="msg-item">
                    <Form.Item className="item-line" name="title" label="消息题目" rules={[{required: true, message: "请填写题目"}]}>
                        <Input />
                    </Form.Item>
                    <Form.Item className="item-line" label="接收人" name="recieveType">
                        <Radio.Group name="type" buttonStyle="solid" value={reciever} onChange={revieverChange}>
                            <Radio value={"all"}>全部</Radio>
                            <Radio value={"level_one"}>注册经销</Radio>
                            <Radio value={"level_two"}>授权经销</Radio>
                            <Radio value={"level_three"}>VIP经销</Radio>
                            <Radio value={"member"}>指定会员</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        reciever === "member" &&
                        <Form.Item className="item-line" name="member" label="会员" rules={[{required: true, message: "请填写接收人"}]}>
                            <DebounceSelect
                                mode="multiple"
                                placeholder="输入用户信息"
                                fetchOptions={fetchUserList}
                                onChange={(newValue) => {
                                    setValue(newValue as UserValue[]);
                                }}
                            />
                        </Form.Item>
                    }
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Form.Item className="editor" name="context" label="消息内容">
                        <MyEditor value={myHtml} onChange={(html) => { setMyHtml(html) }} />
                    </Form.Item>
                    <div className="iphone">
                        <div className="render" dangerouslySetInnerHTML={{ __html: myHtml }}></div>
                    </div>
                </div>
            </Form>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button size="large" type="primary" onClick={submit}>发送</Button>
                <Button style={{ marginLeft: "40px" }} size="large" onClick={()=>navigate(-1)}>取消</Button>
            </div>
        </div>
    )
}

export default MessageAdd;
