import React, { useEffect, useState } from "react";
import axiosInstance from '../../axios/axios.ts';
import { Image, InputNumber, Input, Button, Space, Spin, Select, message, Table } from "antd";
import { useNavigate } from "react-router-dom";
import api from '../../axios/api.ts';
import { UpdateDto } from './update.dto.ts'
import './orderEdit.scss'

const OrderEdit = () => {
    const navigate = useNavigate();
    const [info, setInfo] = useState<any>({})
    const [total, setTotal] = useState(0)
    const [couponFee, setCouponFee] = useState<any>(0)
    const [additionFee, setAdditionFee] = useState<any>(0)
    const [memberFee, setMemberFee] = useState<any>(0)
    const [status, setStatus] = useState<any>();
    const [receive_name, setReceive_name] = useState<any>();
    const [receive_address, setReceive_address] = useState<any>();
    const [receive_phone, setReceive_phone] = useState<any>();
    const [province_id, setProvince_id] = useState<any>("");
    const [city_id, setCity_id] = useState<any>("");
    const [region_id, setRegion_id] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [province_options, setProvince_options] = useState<any>([]);
    const [city_options, setCity_options] = useState<any>([]);
    const [region_options, setRegion_options] = useState<any>([]);
    const [send_id, setSend_id] = useState<any>(null)

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const columns=[
        {
            title: "产品名称",
            key: "name",
            render:(_,record)=>{
                return (
                    <div>
                        <Image style={{width:"60px"}} src={record.product.pic_url[0]}/>
                        <span>{record.product.name}</span>
                    </div>
                )
            }
        },
        {
            title: "发货仓",
            key: "warehouse",
            render:(_,record)=>{
                return record.warehouse.name
            }
        },
        {
            title: "货号",
            key: "id",
            render:(_,record)=>{
                return record.product.sku_id
            }
        },
        {
            title: "价格",
            key: "price",
            render:(_,record)=>{
                return record.product.price.toFixed(2)
            }
        },
        {
            title: "数量",
            key: "amount",
            dataIndex: "amount"
        }
    ]
    useEffect(() => {
        setLoading(true);
        axiosInstance.post(api.getProvinceOptions)
            .then(res => {
                setProvince_options(res.data)
            })

        axiosInstance.post(api.getOrderDetail, { id })
            .then(res => {
                setInfo(res.data)
                setMemberFee(res.data.member_fee)
                setAdditionFee(res.data.addition_fee)
                setCouponFee(res.data.coupon_fee)
                setStatus(res.data.status)
                setReceive_address(res.data.receive_address)
                setReceive_name(res.data.receive_name)
                setReceive_phone(res.data.receive_phone)
                setProvince_id(res.data.province.id)
                setCity_id(res.data.city.id)
                setRegion_id(res.data.region.id)
                setSend_id(res.data.send_id)
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])

    useEffect(() => {
        if (province_id === "" || province_id === undefined)
            return
        console.log("province_id", province_id)
        setCity_id("");
        setRegion_id("");
        axiosInstance.post(api.getCityOptions, { provinceId: province_id })
            .then(res => {
                setCity_options(res.data)
            })
    }, [province_id])

    useEffect(() => {
        if (city_id === "" || city_id === undefined)
            return
        setRegion_id("");
        axiosInstance.post(api.getRegionOptions, { cityId: city_id })
            .then(res => {
                setRegion_options(res.data)
            })
    }, [city_id])

    useEffect(() => {
        if(!info.skus)
            return;

        let tempTotal=0;
        info.skus.forEach((item)=>{
            tempTotal +=   item.service_fee + item.product_fee + item.warehouse_fee + item.shelf_fee + item.addition_fee
        })
        tempTotal -= info.coupon_fee;
        setTotal( tempTotal );
    }, [info])

    const updateInfo=(index,key,value)=>{
        const copy = JSON.parse(JSON.stringify(info));
        copy.skus[index][key] = value;
        setInfo(copy)
    }

    const updateAddress=(key,value)=>{
        const copy = JSON.parse(JSON.stringify(info));
        copy[key]["id"] = value;
        if(key==="province"){
            setProvince_id(value);
            copy["city"]["id"] = null;
            copy["region"]["id"] = null;
        }else if(key==="city"){
            setCity_id(value)
            copy["region"]["id"] = null;
        }else if(key==="region"){
            setRegion_id(value)
        }
        setInfo(copy)
    }

    const submit = () => {
        const id = queryParams.get('id')
        if(!id){
            message.error("订单ID不存在");
        }
        info.pay_actual = total;
        axiosInstance.post(api.editOrder, info)
            .then(() => {
                message.success("更新成功")
            })
    }

    return (
        <div>
            <div className="product-info">
                <h2>商品信息</h2>
                <Table columns={columns} dataSource={info.skus || []} pagination={false}></Table>
                <div className="total-price">
                    <div>总金额：</div>
                    <div className="price-number"><span className="price-label">￥</span>{info?.pay_actual?.toFixed(2) || "-"}</div>
                </div>
            </div>
            <div className="order-info">
                <h2>订单信息</h2>
                <div className="order-container">
                    <div className="order-info-item">
                        <label className="label">订单号:</label>
                        <div>{info?.id}</div>
                    </div>
                    <div className="order-info-item">
                        <label className="label">下单日期:</label>
                        <div>{new Date(info?.create_time).toLocaleString()}</div>
                    </div>
                    {
                        info?.skus?.map((sku, skuIndex)=>{
                            return(
                                <div key={skuIndex} className="order-item-container">
                                    <div className="order-info-item">
                                        <label className="label">商品名称:</label>
                                        <span>{sku.product.name}</span>
                                    </div>
                                    <div className="order-info-item">
                                        <label className="label">重量:</label>
                                        <InputNumber addonAfter={"kg"} disabled={true} value={sku.product.weight} />
                                    </div>
                                    <div className="order-info-item">
                                        <label className="label">体积:</label>
                                        <InputNumber addonAfter={"cm³"} disabled={true} value={sku.product.length*sku.product.width*sku.product.height} />
                                    </div>
                                    <div className="order-info-item">
                                        <label className="label">木箱费:</label>
                                        <InputNumber addonAfter={"元"} disabled={status !== "FOR_PAY"} value={sku.shelf_fee} onChange={val => { updateInfo(skuIndex,"shelf_fee",val) }} />
                                    </div>
                                    <div className="order-info-item">
                                        <label className="label">选仓费:</label>
                                        <InputNumber addonAfter={"元"} disabled={status !== "FOR_PAY"} value={sku.warehouse_fee} onChange={val => { updateInfo(skuIndex,"warehouse_fee",val) }} />
                                    </div>
                                    <div className="order-info-item">
                                        <label className="label">商品价格:</label>
                                        <InputNumber addonAfter={"元"} disabled={status !== "FOR_PAY"} value={sku.product_fee} onChange={val => { updateInfo(skuIndex, "product_fee", val) }} />
                                    </div>
                                    <div className="order-info-item">
                                        <label className="label">运服费:</label>
                                        <InputNumber addonAfter={"元"} disabled={status !== "FOR_PAY"} value={sku.service_fee} onChange={val => { updateInfo(skuIndex, "service_fee", val) }} />
                                    </div>
                                    <div className="order-info-item">
                                        <label className="label">附加费:</label>
                                        <InputNumber addonAfter={"元"} disabled={status !== "FOR_PAY"} value={sku.addition_fee} onChange={val => { updateInfo(skuIndex, "addition_fee", val) }} />
                                    </div>
                                   
                                </div>
                            )
                        })
                    }
                    <div className="order-info-item">
                         <div className="order-info-item">
                            <label className="label">优惠券减免:</label>
                            <InputNumber addonAfter={"元"} disabled value={info.coupon_fee} />
                        </div>
                         <div className="order-info-item">
                            <label className="label">订单备注:</label>
                            <div>{info.desc}</div>
                        </div>
                    </div>
                    <div className="order-info-item">
                        <label className="label">订单总额:</label>
                        <div style={{ color: "red", fontSize: "25px", fontWeight: "bold" }}>{total.toFixed(2)}</div>
                        元
                    </div>
                </div>
            </div>
            <div className="member-info">
                <h2>会员信息</h2>
                <div className="member-container">
                    <div className="member-item">
                        <label className="label">会员ID:</label>
                        <div>{info?.user?.id}</div>
                    </div>
                    <div className="member-item">
                        <label className="label">会员名称:</label>
                        <div>{info?.user?.user_nickname}</div>
                    </div>
                    <div className="member-item">
                        <label className="label">会员等级:</label>
                        <div>{info?.user?.user_level === 1 ? "注册会员" : info?.user?.user_level === 2 ? "经销会员" : "VIP会员"}</div>
                    </div>
                    <div className="member-item">
                        <label className="label">手机号:</label>
                        <div>{info?.user?.user_phone}</div>
                    </div>
                    <div className="member-item">
                        <label className="label">邮箱:</label>
                        <div>{info?.user?.email}</div>
                    </div>
                    <div className="member-item">
                        <label className="label">公司名称:</label>
                        <div>{info?.user?.company_name}</div>
                    </div>
                </div>
            </div>
            <div className="reviever-info">
                <h2>收货人信息</h2>
                <div className="reviever-container">
                    <div className="reviever-item">
                        <label className="label">收货人:</label>
                        <Input value={receive_name} onChange={e => { setReceive_name(e.target.value) }} />
                    </div>
                    <div className="reviever-item">
                        <label className="label">收货手机号:</label>
                        <Input value={receive_phone} onChange={e => { setReceive_phone(e.target.value) }} />
                    </div>
                    <div className="reviever-item" style={{ width: "100%" }}>
                        <label className="label">省：</label>
                        <Select style={{ width: "150px" }} options={province_options} value={info?.province?.id} onChange={province => { updateAddress("province", province) }}></Select>
                        <label className="label">市：</label>
                        <Select style={{ width: "150px" }} options={city_options} value={info?.city?.id} onChange={city => { updateAddress("city", city) }}></Select>
                        <label className="label">区：</label>
                        <Select style={{ width: "150px" }} options={region_options} value={info?.region?.id} onChange={region => { updateAddress("region", region) }}></Select>
                        <label className="label">详细地址:</label>
                        <Input value={receive_address} onChange={e => { setReceive_address(e.target.value) }} />
                    </div>
                    {
                      (  info.status === "SENDING" || info.status==="COMPLETE") &&
                        <div className="reviever-item">
                            <label className="label">快递单号</label>
                            <Input value={send_id} onChange={e=>{setSend_id(e.target.value)}}/>
                        </div>
                    }
                </div>
            </div>
            <div className="member-info">
                <h2>付款信息</h2>
                <div className="member-container">
                    <div className="member-item">
                        <label className="label">收款银行:</label>
                        <div>{info?.receive_bank}</div>
                    </div>
                    <div className="member-item">
                        <label className="label">收款账号:</label>
                        <div>{info?.receive_account}</div>
                    </div>
                    <div className="member-item">
                        <label className="label">付款人:</label>
                        <div>{info?.payer}</div>
                    </div>
                    <div className="member-item">
                        <label className="label">支付方式:</label>
                        <div>{info?.pay_method==="bank"?"对公转账": info?.pay_method==null?"未付款":"微信付款"}</div>
                    </div>
                    {
                        info?.pay_methor!=="bank" &&
                        <div className="member-item">
                            <label className="label">微信支付ID</label>
                            <div>{info?.wx_pay_id || "-"}</div>
                        </div>
                    }
                    <div className="member-item">
                        <label className="label">收款单备注:</label>
                        <div>{info?.receive_desc}</div>
                    </div>
                </div>
            </div>
            <div className="btn-container">
                <Space size="large">
                    <Button type="primary" size="large" onClick={submit}>保存</Button>
                    <Button size="large" onClick={() => { navigate(-1) }}>返回</Button>
                </Space>
            </div>
            {
                loading &&
                <Spin size="large" className="loading" />
            }
        </div>
    )

}

export default OrderEdit;
