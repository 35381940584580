import React from "react";
import { Button, Space, Table, Modal, Form, message, Popconfirm, InputNumber } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import AddProp from "../../components/addProp/addProp.tsx";
import axiosInstance from '../../axios/axios.ts'
import api from '../../axios/api.ts'
import './propList.scss'
import Link from "antd/es/typography/Link";
interface PageDto {
    page?: number;
    size?: number;
}


const PropList: React.FC = () => {

    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>("添加产品属性");
    const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
    const [tableLoading, setTableLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(1);
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [total, setTotal] = React.useState<number>(0);
    const [propId, setPropId] = React.useState<number | null>(null);
    const [disable, setDisable] = React.useState<boolean>(false);
    const [editingRecord, setEditingRecord] = React.useState(null);
    const [index, setIndex] = React.useState();
    const [form] = Form.useForm();

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };


    React.useEffect(() => {
        loadDate()
    }, [page, pageSize])

    React.useEffect(() => {
        if (selectedRowKeys.length === 0) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [selectedRowKeys])

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setPageSize(size);
    }

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }


    const startEdit = (record) => {
        setEditingRecord(record);
    };

    const confirmIndex=()=>{
        axiosInstance.post(api.updateIndex,{id: (editingRecord as any).id, index})
        .then(()=>{
            message.success("更新成功", 3);
            loadDate();
        })
    }



    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '商品属性',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '顺序',
            render: (record) => {
                return (
                    <div>
                        <span>{record.index}</span>
                        <Popconfirm
                            title="编辑"
                            //@ts-expect-error ensure the value
                            description={(<InputNumber onChange={val => { setIndex(val) }} />)}
                            trigger="click"
                            okText="确认"
                            cancelText="取消"
                            onConfirm={confirmIndex}
                        >
                            <EditOutlined style={{ cursor: "pointer" }} onClick={() => startEdit(record)} />
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            title: '操作',
            render: (record) => {
                return (
                    <Space key={"item" + record.id}>
                        <Link onClick={() => handleEdit(record)}>编辑</Link>
                        <Popconfirm title="确认删除？" okText="确认" cancelText="取消" onConfirm={() => deleteProp(record)}>
                            <Link>删除</Link>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleEdit = (record) => {
        setPropId(record.id);
        setTitle("编辑产品属性")
        setIsModalOpen(true);
        form.setFieldsValue({
            id: record.id,
            name: record.name,
            attributes: record.attributes
        })
    }

    const handleSubmit = () => {
        setSubmitLoading(true);
        form.validateFields()
            .then(values => {
                if (propId === null) {
                    //添加
                    createProp(values);
                } else {
                    //更新
                    upDateProp(values);
                }
            })
            .catch(() => {

            })
    }

    const createProp = (values) => {
        axiosInstance.post(api.addProp, values)
            .then(res => {
                if (res.data === true) {
                    message.success("创建成功", 3)
                    form.resetFields();
                    setIsModalOpen(false);
                    loadDate(1)
                }
            })
            .finally(() => {
                setSubmitLoading(false);

            })
    }

    const upDateProp = (values) => {
        axiosInstance.post(api.updateAll, { id: propId, createPropDto: values })
            .then(() => {
                message.success("更新成功", 3);
                setPropId(null);
                setIsModalOpen(false);
                setSubmitLoading(false);
                loadDate();
            })
    }

    const deleteProp = (record) => {
        console.log(record)
        const idList = [record.id];
        axiosInstance.post(api.delete, idList)
            .then(() => {
                message.success("删除成功", 3);
                loadDate();
            })
    }

    const deleteAllProp = () => {
        console.log(selectedRowKeys)
        axiosInstance.post(api.delete, selectedRowKeys)
            .then(() => {
                message.success("删除成功", 3);
                loadDate();
            })
    }

    const cancel = () => {
        setSubmitLoading(false);
        form.resetFields();
        setIsModalOpen(false)
    }

    const loadDate = (newPage?: number) => {
        setTableLoading(true)
        const pageDto: PageDto = {
            page: newPage || page,
            size: pageSize
        }
        axiosInstance.post(api.queryPropByPage, pageDto)
            .then(res => {
                res.data.result.forEach(item => {
                    item.key = item.id;
                })
                setTableData(res.data.result)
                setTotal(res.data.total)
            })
            .finally(() => {
                setTableLoading(false)
            })
    }

    

    

    return (
        <div className="prop-list">
            <div className='tools'>
                <Space size="middle">
                    <Button type='primary' onClick={() => { setIsModalOpen(true); setTitle("添加产品属性") }}>添加属性</Button>
                    <Button danger disabled={disable} onClick={deleteAllProp}>批量删除</Button>
                </Space>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={paginationSetting} scroll={{ y: "800" }} loading={tableLoading} />
            </div>
            <Modal
                open={isModalOpen}
                width={700}
                closable={false}
                title={title}
                onOk={() => handleSubmit()}
                onCancel={cancel}
                okText="提交"
                confirmLoading={submitLoading}
                cancelText="取消">
                <AddProp form={form} />
            </Modal>
        </div>
    )
}

export default PropList;