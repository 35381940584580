import React, { useEffect } from "react";
import { Button, Space, Table, Form, Modal, Input, Select, InputNumber, DatePicker, Popconfirm, Radio } from 'antd';
import {useNavigate} from "react-router-dom"
import axiosInstance from "../../axios/axios.ts";
import dayjs from 'dayjs';
import api from "../../axios/api.ts";

const CouponList = () => {

    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [page, setPage] = React.useState<number>(1);
    const [size, setSize] = React.useState<number>(10);
    const [total, setTotal] = React.useState<number>(0);
    const [disable, setDisable] = React.useState(true);
    const [tableLoading, setTableLoading] = React.useState<boolean>(false);
    const [visible, setVisible] = React.useState(false);
    const [typeOptions, setTypeOptions] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(false);
    const [record, setRecord] = React.useState<any>({})
    const [type, setType] = React.useState<string>('')
    const [endType, setEndType] = React.useState<string>("range")
    const [isAllType, setIsAllType] = React.useState<string>("all")
    const [product, setProduct] = React.useState<any[]>([])

    useEffect(() => {
        if (selectedRowKeys.length === 0) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [selectedRowKeys])

    useEffect(() => {
        axiosInstance.post(api.getTypeTag)
            .then(res => {
                res.data.forEach(item => {
                    item.label = item.title,
                        item.value = item.tag
                })
                setTypeOptions(res.data)
            })
        loadDate()
    }, [])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        loadDate(page)
    }

    const loadDate = (newPage?: number, searchParams = {} as any) => {
        setTableLoading(true)
        const pageDto = {
            page: newPage || page,
            size: size,
        }
        axiosInstance.post(api.queryCoupon, pageDto)
            .then(res => {
                res.data.result.forEach(item => {
                    item.key = item.id;
                })
                setTableData(res.data.result)
                setTotal(res.data.total)
            })
            .finally(() => {
                setTableLoading(false)
            })
    }

    const createCoupon = () => {
        setType("create")
        form.resetFields();
        setVisible(true)
    }

    const submit = () => {
        form.validateFields()
            .then(res => {
                console.log(res)
                const params: any = {
                    id: undefined,
                    name: res.name,
                    limit: res.limit,
                    get_start_time: res.get_start_time.format("YYYY-MM-DD"),
                    get_end_time: res.get_end_time.format("YYYY-MM-DD"),
                    threshold: res.threshold,
                    coupon_fee: res.coupon_fee,
                    amount: res.amount,
                    desc: res.desc,
                    limit_people: res.limit_people,
                    end_type: res.end_type,
                    is_all_type: res.is_all_type
                }
                if(res.is_all_type==="all"){
                    params.type_id = [];
                    typeOptions.forEach(item=>{
                        params.type_id.push(item.value)
                    })
                }else if(res.is_all_type==="part"){
                    params.type_id = res.type_id
                }else {
                    params.type_id = [];
                    params.product = res.products;
                }
                if(res.end_type==="range"){
                    params.start_time= res.start_time.format("YYYY-MM-DD");
                    params.end_time = res.end_time.format("YYYY-MM-DD");
                }else {
                    params.limit_days = res.limit_days;
                }
                setLoading(true)
                if (type === "create") {
                    return axiosInstance.post(api.addCoupon, params)
                } else {
                    params.id = record.id;
                    return axiosInstance.post(api.updateCoupon, params)
                }
            })
            .then(res => {
                loadDate()
                setVisible(false)
            })
            .catch(e => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const edit = (record) => {
        setType("edit")
        setRecord(record);
        axiosInstance.post(api.getProductFromIds,{ids: record.products})
            .then(res=>{
                const options = res.data.map(item=>{
                    return {label: item.name, value: item.id}
                })
                setProduct(options)
                form.setFieldValue("products", record.products)
            })
        setVisible(true);
        setEndType(record.end_type);
        setIsAllType(record.is_all_type)
        form.setFieldsValue({
            is_all_type: record.is_all_type,
            end_type: record.end_type,
            limit_days: record.limit_days,
            name: record.name,
            type_id: record.type_id,
            limit: record.limit,
            threshold: record.threshold,
            coupon_fee: record.coupon_fee,
            amount: record.amount,
            get_start_time: dayjs(record.get_start_time,'YYYY/MM/DD'),
            get_end_time: dayjs(record.get_end_time,'YYYY/MM/DD'),
            desc: record.desc,
            limit_people: record.limit_people,
        })
        if(record.start_time!==null){
            form.setFieldValue("start_time", dayjs(record.start_time, 'YYYY/MM/DD'));
        }
        if(record.end_time!==null){
            form.setFieldValue("end_time", dayjs(record.end_time, 'YYYY/MM/DD'))
        }
    }

    const handleDelete=(record)=>{
        axiosInstance.post(api.deleteCoupon,{id:[record.id]})
        .then(res=>{
            loadDate()
        })
    }

    const deleteAll=()=>{
        axiosInstance.post(api.deleteCoupon,{id:selectedRowKeys})
        .then(res=>{
            loadDate()
            setSelectedRowKeys
        })
    }

    const searchProductByName=(newValue)=>{
        axiosInstance.post(api.queryProductByName, {name: newValue})
            .then(res=>{
                setProduct(res.data)
            })
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "优惠券名称",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "品类",
            key: "type_name",
            render: (_, record) => {
                if(record.is_all_type==="all"){
                    return "全品类"
                }else if(record.is_all_type==="part"){
                    return "部分品类"
                }else {
                    return "特定商品"
                }
            }
        },
        {
            title: "有效期",
            dataIndex: "end_time",
            key: "end_time",
            render: (text, record) => {
                if(text===null){
                    return record.limit_days + '天'
                }else{
                    return new Date(text).toLocaleDateString()
                }
            }
        },
        {
            title: "剩余数量",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "优惠金额",
            dataIndex: "coupon_fee",
            key: "coupon_fee",
        },
        {
            title: "数量限制",
            dataIndex: "limit",
            key: "limit",
        },
        {
            title: "操作",
            key: "action",
            render: (_, record) => {

                return (
                    <Space>
                        <a onClick={() => edit(record)}>编辑</a>
                        <Popconfirm onConfirm={()=>handleDelete(record)} title="警告" okType="danger" okText="删除" description="确定要删除吗" cancelText="取消">
                            <a style={{ color: "red" }}>删除</a>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ];

    return (
        <div className="coupon-list">
            <div className='tools'>
                <Space size="middle">
                    <Button type="primary" onClick={createCoupon}>新建优惠券</Button>
                    <Button type="primary" onClick={()=>{navigate("/coupon/send")}}>发放优惠券</Button>
                    <Button danger disabled={disable} onClick={deleteAll}>批量删除</Button>
                </Space>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={paginationSetting} scroll={{ y: "800" }} loading={tableLoading} />
            </div>
            <Modal
                title={type==="edit"?"编辑优惠券":"新建优惠券"}
                okText="提交"
                cancelText="取消"
                open={visible}
                closable={false}
                onCancel={() => { setVisible(false) }}
                onOk={submit}
                destroyOnClose
                confirmLoading={loading}
            >
                <Form form={form}>
                    <Form.Item label="名称" name="name" rules={[{ required: true, message: "请填写名称" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={"使用品类"} name={"is_all_type"} initialValue={"all"}>
                        <Radio.Group value={isAllType} onChange={e=>{setIsAllType(e.target.value)}}>
                            <Radio value={"all"}>全品类</Radio>
                            <Radio value={"part"}>部分品类</Radio>
                            <Radio value={"product"}>特定商品</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="领取人群" name="limit_people" rules={[{required: true, message: "请选择领取人群"}]}>
                        <Select mode="multiple" options={[
                            {label: "注册经销",value: 1},
                            {label: "授权经销", value: 2},
                            {label: "VIP经销", value: 3}
                        ]}></Select>
                    </Form.Item>
                    {
                        isAllType==="part" &&
                        <Form.Item label={"商品类别"} name="type_id" rules={[{ required: true, message: "请选择分类" }]}>
                            <Select mode="multiple" options={typeOptions} placeholder="请选择商品分类"></Select>
                        </Form.Item>
                    }
                    {
                        isAllType==="product" &&
                        <Form.Item label={"可用商品"} name={"products"} rules={[{required: true, message: "请选择"}]}>
                            <Select mode="multiple" options={product} showSearch onSearch={searchProductByName}></Select>
                        </Form.Item>
                    }
                    <Form.Item label="每人限领" name="limit" rules={[{ required: true, message: "请填写" }]}>
                        <InputNumber style={{width: "100%"}}  addonAfter="张" />
                    </Form.Item>
                    <div style={{ display: "flex" }}>
                        <Form.Item initialValue={0} name="threshold" rules={[{ required: true, message: "请填写" }]}>
                            <InputNumber addonBefore={"满"} addonAfter={"元"} min={0} />
                        </Form.Item>
                        <Form.Item name="coupon_fee" rules={[{ required: true, message: "请填写" }]}>
                            <InputNumber addonBefore={"减"} addonAfter={"元"} min={0} />
                        </Form.Item>
                    </div>
                    <Form.Item name="amount" label="总张数" rules={[{ required: true, message: "请填写" }]}>
                        <InputNumber style={{width: "100%"}} min={0} />
                    </Form.Item>
                    <div style={{display: "flex"}}>
                        <span style={{paddingTop: "5px"}}>领取时间：</span>
                        <Form.Item name={"get_start_time"} rules={[{ required: true, message: "请填写" }]}>
                            <DatePicker placeholder="开始日期" />
                        </Form.Item>~
                        <Form.Item name={"get_end_time"} rules={[{ required: true, message: "请填写" }]}>
                            <DatePicker placeholder="结束日期"></DatePicker>
                        </Form.Item>
                    </div>
                    <Form.Item name="end_type" label="有效期方式" initialValue={"range"}>
                        <Radio.Group value={endType} onChange={e=>{setEndType(e.target.value)}}>
                            <Radio value="range">固定时间段</Radio>
                            <Radio value="days">自定义天数</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        endType==="range"?
                            <div style={{display: "flex"}}>
                                <span style={{paddingTop: "5px"}}>使用时间：</span>
                                <Form.Item name="start_time" rules={[{ required: true, message: "请填写" }]}>
                                    <DatePicker placeholder="开始日期" />
                                </Form.Item>~
                                <Form.Item name="end_time" rules={[{ required: true, message: "请填写" }]}>
                                    <DatePicker placeholder="结束日期" />
                                </Form.Item>
                            </div>:
                            <Form.Item name={"limit_days"} label={"天数"} rules={[{ required: true, message: "请填写" }]}>
                                <Input/>
                            </Form.Item>
                    }
                    <Form.Item label={"使用规则"} name={"desc"}>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default CouponList;
