import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Form, Input, Select, Checkbox, message } from "antd";
import './shippingAddress.scss'
import axiosInstance from "../../axios/axios.ts";
import api from "../../axios/api.ts";

const ShippingAddress: React.FC = () => {
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [type, setType] = useState<string>("");
    const [record, setRecord] = useState<any>(null);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [provinceOption, setProvinceOption] = useState<{ label: string, value: string }[]>([])
    const [cityOption, setCityOption] = useState<{ label: string, value: string }[]>([])
    const [regionOption, setRegionOption] = useState<{ label: string, value: string }[]>([])
    const [province, setProvince] = useState<string>("");
    const [city, setCity] = useState<string>("");

    useEffect(() => {
        loadData();
        axiosInstance.post(api.getProvinceOptions).then(res => {
            setProvinceOption(res.data)
        })
    }, [])

    useEffect(()=>{
        axiosInstance.post(api.getCityOptions, { provinceId:province }).then(res => {
            setCityOption(res.data)
        })
    },[province])
    useEffect(()=>{
        axiosInstance.post(api.getRegionOptions, { cityId: city }).then(res => {
            setRegionOption(res.data)
        })
    },[city])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const pageChange = (page: number, size: number) => {
        setPage(page);
        setSize(size);
    }

    const loadData = (newPage?: number) => {
        const pageDto = {
            page: newPage || page,
            size: size,
        }
        setTableLoading(true);
        axiosInstance.post(api.queryShipping, pageDto).then(res => {
            setTableData(res.data.result)
        })
            .finally(() => {
                setTableLoading(false);
            })
    }

    const edit = (record) => {
        setModalVisible(true);
        setRecord(record)
        setType("edit");
        form.setFieldsValue(record);
        form.setFieldValue("province", record.province.id)
        setProvince(record.province.id)
        form.setFieldValue("city", record.city.id);
        setCity(record.city.id);
        form.setFieldValue("region", record.region.id);
    }

    const submit = () => {
        form.validateFields().then((values) => {
            if (type === "edit") {
                values.id = record.id;
                axiosInstance.post(api.updateShipping, values).then(res => {
                    message.success("修改成功")
                    setModalVisible(false)
                    loadData();
                })
            } else {
                axiosInstance.post(api.addShipping, values).then(res => {
                    console.log(res)
                    message.success("添加成功")
                    setModalVisible(false)
                    loadData();
                })
            }
        })
    }

    const provinceChange = (provinceId) => {
        setProvince(provinceId)
        form.setFieldValue('city', undefined);
        form.setFieldValue('region', undefined);
        
    }

    const cityChange = (cityId) => {
        setCity(cityId)
        form.setFieldValue('region', undefined);
        

    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: "发货人",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "发货人电话",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "发货地址",
            key: "address",
            render: (_, record) => {
                console.log(record)
                return (
                    <div>{record.province.name}-{record.city.name}-{record.region.name}-{record.detail}</div>
                )
            }
        },
        {
            title: "操作",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => edit(record)}>编辑</a>
                    <a>删除</a>
                </Space>
            ),
        }
    ]

    const paginationSetting = {
        current: page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: total,
        onChange: pageChange
    }

    return (
        <div className="shipping-address">
            <div className='tools'>
                <Space size="large">
                    <Button type='primary' onClick={() => { setModalVisible(true) }} >添加发货地址</Button>
                    <Button danger >批量删除</Button>
                </Space>
            </div>
            <div className='table'>
                <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={paginationSetting} loading={tableLoading} />
            </div>
            <Modal
                okText="提交"
                cancelText="取消"
                title="发货地址"
                open={modalVisible}
                closable={false}
                destroyOnClose
                width={800}
                onOk={() => submit()}
                onCancel={() => setModalVisible(false)}
            >
                <Form
                    name="shipping"
                    form={form}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                >
                    <Form.Item name="name" label="姓名" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="phone" label="手机号" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="province" label="省份" rules={[{ required: true }]}>
                        <Select placeholder="请选择省" options={provinceOption} onChange={provinceChange}></Select>
                    </Form.Item>
                    <Form.Item name="city" label="城市" rules={[{ required: true }]}>
                        <Select placeholder="请选择市" options={cityOption} onChange={cityChange}></Select>
                    </Form.Item>
                    <Form.Item name="region" label="区域" rules={[{ required: true }]}>
                        <Select placeholder="请选择区" options={regionOption}></Select>
                    </Form.Item>
                    <Form.Item name="detail" label="详细地址" rules={[{ required: true }]}>
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="tel" label="固定电话">
                        <Input />
                    </Form.Item>
                    <Form.Item name="postal_code" label="邮政编码" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="company" label="公司名称">
                        <Input />
                    </Form.Item>
                    <Form.Item name="is_default" label="是否默认" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ShippingAddress;