import React, { useEffect, useState } from "react";
import { Form, Input, Select, Upload, Button, Modal, InputNumber, Radio, Space, TreeSelect, message } from "antd";
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import type { UploadFile, UploadProps, RcFile } from 'antd/es/upload/interface';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import DraggableUploadListItem from './components/draggableUploadListItem.tsx'
import MyEditor from "../../components/editor/editor.tsx";
import api from "../../axios/api.ts";
import './skuAdd.scss';
import axiosInstance from '../../axios/axios.ts';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'


const uploadButton = (
    <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
    </div>
);


const { Dragger } = Upload;



const SkuAdd: React.FC = () => {
    const [form] = Form.useForm();
    const location = useLocation();
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [myHtml, setMyHtml] = useState<string>("");
    const [tags, setTags] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [propOptions, setPropOptions] = useState([]);
    const [specOptions, setSpecOptions] = useState([]);
    const [provinceOptions, setProvinceOptions] = useState([])
    const [page, setPage] = useState<string>("1");
    const [cerateLoading, setCreateLoading] = useState<boolean>(false)
    const [propId, setPropId] = useState<Set<any>>()
    const [mode, setMode] = useState<string>("add")
    const [shippingTypeOptions, setShippingTRypeOptions] = useState([]);
    const queryParams = new URLSearchParams(location.search);
    const total = queryParams.get('total');
    const [messageApi, contextHolder] = message.useMessage();


    const sensor = useSensor(PointerSensor, {
        activationConstraint: { distance: 10 },
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (propId !== undefined) {
            console.log("PropId", propId)
            axiosInstance.post(api.getPropTreeOptions, { propId: Array.from(propId) })
                .then(res => {
                    setPropOptions(res.data);
                })
        }
    }, [propId])

    //ComponentDidMount
    useEffect(() => {
        axiosInstance.post(api.optionShippingType)
            .then(res=>{
                if((res as any).code===0){
                    setShippingTRypeOptions(res.data.data)
                }
            })
        setMode(queryParams.get("mode") || "add");
        axiosInstance.post(api.getTypeOptions).then((res) => {
            if ((res as any).code === 0) {
                setTypeOptions(res.data);
            }
        })

        axiosInstance.post(api.getProvinceOptions)
            .then((res: any)=>{
                if(res.code===0){
                    setProvinceOptions(res.data)
                }
            })
        axiosInstance.post(api.getSpecOptions)
            .then(res => {
                setSpecOptions(res.data);
            })

        setPage(queryParams.get("page") || "1")
        // 从查询参数中获取值
        const id = queryParams.get('id');
        if (id) {
            axiosInstance.post(api.getProductFromId, { id }).then(res => {
                if ((res as any).code === 0) {
                    console.log(res)
                    form.setFieldsValue(res.data);
                    form.setFieldValue("warehouse", res.data.warehouses.map(item => item.id));
                    setMyHtml(res.data.detail);
                    const pic = res.data.pic_url.map(item => ({
                        url: item,
                        uid: item,
                    }));
                    setFileList(pic);
                }
                return axiosInstance.post(api.getPropIdByTypeId, { typeId: res.data.type_id })
            })
                .then(res => {
                    const propId: Set<number> = new Set([res.data]);
                    setPropId(propId);
                })
        }
    }, [])

    useEffect(() => {
        form.setFieldValue('detail', myHtml);
    }, [myHtml])

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setFileList((prev) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id);
                const overIndex = prev.findIndex((i) => i.uid === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onChangeProps = (value: any) => {
        form.setFieldValue('prop_id', value);
    }

    const findPropIdByValue = (typeIds: string[]) => {
        console.log("typeIds", typeIds)
        const propIdSet = new Set();
        function findPropId(values: string[], options: any[]) {
            typeIds.forEach((typeId) => {
                options.forEach((item: any) => {
                    if (item.value == typeId) {
                        propIdSet.add(item.propId);
                    }
                    if (item.children) {
                        findPropId(values, item.children);
                    }
                })
            });
        }
        findPropId(typeIds, typeOptions);
        return propIdSet;
    }

    const onChangeOptions = (value: any) => {
        form.setFieldValue('type_id', value);
        setPropId(findPropIdByValue(value))
    };

    useEffect(() => {
        const picArr = fileList.map((item) => {
            if (item.url !== undefined) {
                return item.url;
            } else {
                return item.response?.data?.url
            }
        });
        form.setFieldValue('pic_url', picArr);
    }, [fileList])

    const { Option } = Select;
    const handleChange = (value) => {
        setTags(value)
    };

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleCancel = () => setPreviewOpen(false);

    //提交
    const onSubmit = () => {
        setCreateLoading(true)
        const id = queryParams.get('id');
        form.validateFields().then(values => {
            if (id && mode === 'edit') {
                axiosInstance.post(api.updateProduct, values)
                    .then((res) => {
                        const response: any = res;
                        if (response.code === 0) {
                            messageApi.success("修改成功");
                        } else {
                            messageApi.error(response.msg);
                        }
                    })
                    .finally(() => {
                        setCreateLoading(false);
                    })
            } else {
                axiosInstance.post(api.addProduct, values).then(res => {
                    const response: any = res;
                    if (response.code === 0) {
                        messageApi.success("添加成功");
                        navigate("/skulist");
                    } else {
                        messageApi.error(response.msg);
                    }
                })
                    .finally(() => {
                        setCreateLoading(false)
                    })
            }
        }).catch(err => { console.log(err) })
    }

    const goBack = () => {
        navigate(`/skulist?page=${page}`);
    }

    const selectAllProvince=(name)=>{
        const copy = form.getFieldValue("warehouses")
        copy[name].available_provinces = provinceOptions.map(item=>item.value)
        form.setFieldValue("warehouses", copy)
    }

    return (
        <div className="sku-add">
            {contextHolder}
            <div className="title">添加商品</div>
            <div className="form">
                <Form
                    name="skuAdd"
                    layout="vertical"
                    style={{ maxWidth: "100%" }}
                    form={form}
                    initialValues={{ warehouse: [{ name: "" }] }}
                >
                    <div className="line">
                        <Form.Item style={{ display: "none" }} label="id" name="id">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className="line-item"
                            label="商品名称"
                            name="name"
                            rules={[{ required: true, message: '请输入商品名称!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item className="line-item" label="商品分类" name="type_id" initialValue={[]}>
                            <TreeSelect
                                showSearch
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="请选择商品分类"
                                allowClear
                                treeDefaultExpandAll
                                onChange={onChangeOptions}
                                treeData={typeOptions}
                                treeCheckable={true}
                                showCheckedStrategy="SHOW_PARENT"
                            />
                        </Form.Item>
                    </div>
                    <div className="line">
                        <Form.Item className="line-item" label="商品属性" name="prop_id">
                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="请选择商品属性"
                                allowClear
                                treeData={propOptions}
                                onChange={onChangeProps}
                                treeCheckable={true}
                                showCheckedStrategy="SHOW_PARENT"
                                treeLine={true}
                            ></TreeSelect>
                        </Form.Item>
                        <Form.Item className="line-item" label="规格/型号" name="spec_id">
                            <Select options={specOptions} mode="multiple" />
                        </Form.Item>
                    </div>
                    <div className="line">
                        <Form.Item
                            className="line-item"
                            label="商品ID"
                            name="sku_id"
                            rules={[{ required: true, message: '请输入商品ID' }]}
                            initialValue={`SBHJL00${total}`}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className="line-item"
                            label="商品简介"
                            name="desc"
                            rules={[{ required: true, message: '请输入商品简介' }]}
                        >
                            <Input.TextArea maxLength={100} />
                        </Form.Item>
                    </div>
                    <div className="line">
                        <Form.Item className="line-item" label="商品图片" name="pic_url">
                            <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                                <SortableContext items={fileList.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
                                    <Upload
                                        accept="png, jpg, jpeg, gif, webp"
                                        action={api.uploadImg}
                                        multiple
                                        fileList={fileList}
                                        listType="picture-card"
                                        onChange={onChange}
                                        onPreview={handlePreview}
                                        itemRender={(originNode, file) => (
                                            <DraggableUploadListItem originNode={originNode} file={file} />
                                        )}
                                    >
                                        {uploadButton}
                                    </Upload>
                                </SortableContext>
                            </DndContext>
                        </Form.Item>
                        <Form.Item
                            className="line-item"
                            label="搜索关键词"
                            name="key_words"
                            rules={[{ required: true, message: '请输入搜索关键词' }]}
                        >
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                placeholder="输入后按 Enter 添加标签"
                                value={tags}
                                onChange={handleChange}
                            >
                                {tags.map(tag => (<Option key={tag} value={tag}>{tag}</Option>))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="line">
                        <Form.Item
                            className="line-item"
                            label="市场价格"
                            name="mark_price"
                            rules={[{ required: true, message: '请输入市场价格' }]}
                        >
                            <InputNumber prefix="￥" style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            className="line-item"
                            label="销售价格"
                            name="price"
                            rules={[{ required: true, message: '请输入销售价格' }]}
                        >
                            <InputNumber prefix="￥" style={{ width: "100%" }} />
                        </Form.Item>
                    </div>
                    <div className="line">
                        <Form.Item
                            className="line-item"
                            label="成本价格"
                            name="cost_price"
                            rules={[{ required: true, message: '请输入成本价格' }]}
                        >
                            <InputNumber prefix="￥" style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            className="line-item"
                            label="注册经销价格"
                            name="member1_price"
                            rules={[{ required: true, message: '请输入注册经销价格' }]}
                        >
                            <InputNumber prefix="￥" style={{ width: "100%" }} />
                        </Form.Item>
                    </div>
                    <div className="line">
                        <Form.Item
                            className="line-item"
                            label="VIP经销价格"
                            name="member3_price"
                            rules={[{ required: true, message: '请输入VIP经销价格' }]}
                        >
                            <InputNumber prefix="￥" style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            className="line-item"
                            label="授权经销价格"
                            name="member2_price"
                            rules={[{ required: true, message: '请输入授权经销价格' }]}
                        >
                            <InputNumber prefix="￥" style={{ width: "100%" }} />
                        </Form.Item>
                    </div>
                    <div className="line">
                        <div className="size">
                            <Form.Item className="line-item" label="重量" name="weight">
                                <InputNumber addonAfter="KG" />
                            </Form.Item>
                            <Form.Item className="line-item" label="长度" name="length">
                                <InputNumber addonAfter="cm" />
                            </Form.Item>
                            <Form.Item className="line-item" label="宽度" name="width">
                                <InputNumber addonAfter="cm" />
                            </Form.Item>
                            <Form.Item className="line-item" label="高度" name="height">
                                <InputNumber addonAfter="cm" />
                            </Form.Item>
                        </div>
                        <div className="shelf">
                            <Form.Item label="是否需要木架" name="need_shelf" initialValue={false}>
                                <Radio.Group>
                                    <Radio value={true}>是</Radio>
                                    <Radio value={false}>否</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                className="shelf-price"
                                label="木架价格"
                                name="shelf_price"
                                initialValue={0}
                                rules={[{ required: true, message: '请输入木架价格' }]}
                            >
                                <InputNumber addonAfter="元" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="line">
                        <Form.Item className="line-item" label="访问权限" name="access" initialValue={"All"}>
                            <Radio.Group>
                                <Radio value={'All'}>所有用户</Radio>
                                <Radio value={'Member'}>仅会员</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item className="line-item" label="商品状态" name="published" initialValue={true}>
                            <Radio.Group>
                                <Radio value={true}>上架</Radio>
                                <Radio value={false}>待上架</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="warehouse">
                        仓库
                        <Form.List name="warehouses">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                label={'仓库名称'}
                                                {...restField}
                                                name={[name, 'name']}
                                                rules={[{ required: true, message: '请输入仓库名称' }]}
                                            >
                                                <Input placeholder="仓库名称" />
                                            </Form.Item>
                                            <Form.Item
                                                label={'库存'}
                                                {...restField}
                                                name={[name, 'stock']}
                                                rules={[{ required: true, message: '请输入库存' }]}
                                            >
                                                <InputNumber placeholder="库存"  style={{width: "120px"}}/>
                                            </Form.Item>
                                            <Form.Item
                                                label={'附加金额'}
                                                {...restField}
                                                name={[name, 'price']}
                                                rules={[{ required: true, message: '请输入附加金额' }]}
                                            >
                                                <InputNumber placeholder="产品附加金额"  style={{width:"120px"}}/>
                                            </Form.Item>
                                            <Form.Item
                                                label={'允许省份'}
                                                {...restField}
                                                name={[name, 'available_provinces']}
                                            >
                                                <Select mode={'multiple'} options={provinceOptions} style={{width: "120px"}} placeholder={'允许省份'}></Select>
                                            </Form.Item>
                                            <Form.Item label={'省份全选'}>
                                                <Button onClick={()=>selectAllProvince(name)}>全选</Button>
                                            </Form.Item>
                                            <Form.Item
                                                label="配送方式"
                                                {...restField}
                                                rules={[{required: true, message: "请选择配送方式"}]}
                                                name={[name, 'shipping_type']}
                                            >
                                                <Select placeholder="请选择配送方式" options={shippingTypeOptions}></Select>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            添加仓库
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </div>
                    <div className="line">
                        <Form.Item className="line-item" name="detail">
                            <MyEditor value={myHtml} onChange={(html) => { setMyHtml(html) }} />
                        </Form.Item>
                        <div className="iphone">
                            <div className="render" dangerouslySetInnerHTML={{ __html: myHtml }}></div>
                        </div>
                    </div>

                </Form>
                <div className="btn-group">
                    <Space>
                        <Button onClick={onSubmit} type="primary" size="large" loading={cerateLoading}>提交</Button>
                        <Button size="large">保存</Button>
                        <Button size="large" onClick={goBack}>返回</Button>
                    </Space>
                </div>
            </div>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>

        </div>
    )
}

export default SkuAdd;
