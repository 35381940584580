import React, { useState, useEffect } from "react";
import { Space, Button, Table, Modal, Form, Input, message, Select, Popconfirm, Upload } from "antd";
import axiosInstance from "../../axios/axios.ts";
import { Link } from "react-router-dom";
import api from "../../axios/api.ts";
import type { UploadProps } from 'antd/es/upload/interface';

import { InboxOutlined } from '@ant-design/icons';
import './areaManage.scss'

const { Dragger } = Upload;


const AreaManage: React.FC = () => {
    const [tableData, setTableData] = useState<any[]>([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [provinceModalVisible, setProvinceModalVisible] = useState<boolean>(false);
    const [cityeModalVisible, setCityModalVisible] = useState<boolean>(false);
    const [regionModalVisible, setRegionModalVisible] = useState<boolean>(false);
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
    const [provinceOptions, setProvinceOptions] = useState<{ label: string, value: string }[]>([])
    const [cityOptions, setCityOptions] = useState<{ label: string, value: string }[]>([])
    const [editRecord, setEditRecord] = useState<any>({})

    const [provinceForm] = Form.useForm();
    const [cityForm] = Form.useForm();
    const [regionForm] = Form.useForm();
    const [editForm] = Form.useForm();
    useEffect(() => {

        loadData();
        axiosInstance.post(api.getProvinceOptions)
            .then(res => {
                console.log(res)
                setProvinceOptions(res.data)
            })
    }, [])

    useEffect(()=>{
        axiosInstance.post(api.getProvinceOptions)
        .then(res => {
            console.log(res)
            setProvinceOptions(res.data)
        })
    },[tableData])

    const loadData = () => {
        setTableLoading(true)
        axiosInstance.post(api.getProvince)
            .then(res => {
                console.log(res)
                setTableData(convertDataToTree(res.data))
            })
    }

    const convertDataToTree = (data) => {
        setTableLoading(false)
        return data.map(province => ({
            key: "p-" + province.id,
            type: "province",
            name: province.name,
            children: province.cities.map(city => ({
                key: "c-" + city.id,
                type: "city",
                name: city.name,
                children: city.districts.map(district => ({
                    key: "d-" + district.id,
                    type: "district",
                    name: district.name
                }))
            }))
        }));
    };

    const submitProvince = () => {
        provinceForm.validateFields().then(values => {
            console.log(values)
            axiosInstance.post(api.addProvince, { province: values.province })
                .then(res => {
                    message.success('添加成功')
                    setProvinceModalVisible(false)
                    loadData()
                })
        })
    }

    const submitCity = () => {
        cityForm.validateFields().then(values => {
            axiosInstance.post(api.addCity, { cityName: values.city, provinceId: values.province })
                .then(res => {
                    message.success('添加成功')
                    setCityModalVisible(false)
                    loadData()
                })
        })
    }

    const submitRegion = () => {
        regionForm.validateFields().then(values => {
            axiosInstance.post(api.addRegion, { regionName: values.region, cityId: values.city })
                .then(res => {
                    console.log(res)
                    message.success('添加成功');
                    setRegionModalVisible(false);
                    loadData();
                })
        })
    }

    const handleProvinceChange = (provinceId) => {
        axiosInstance.post(api.getCityOptions, { provinceId })
            .then(res => {
                setCityOptions(res.data)
            })
    }

    const handleEdit = (record) => {
        setEditRecord(record)
        setEditModalVisible(true)
        editForm.setFieldValue('name', record.name);
    }

    const handleDelete=(record)=>{
        const id=record.key.split("-")[1]
        if(record.type==='province'){
            axiosInstance.post(api.deleteProvince,{id})
            .then(()=>{
                message.success('删除成功')
                loadData()
            })
        }else if(record.type==='city'){
            axiosInstance.post(api.deleteCity,{id})
            .then(()=>{
                message.success('删除成功')
                loadData()
            })
        }else if(record.type==='district'){
            axiosInstance.post(api.deleteRegion,{id})
            .then(()=>{
                message.success('删除成功')
                loadData()
            })
        }
    }

    const submitEdit = () => {
        editForm.validateFields().then(values => {
            if (editRecord.type === 'province') {
                axiosInstance.post(api.updateProvince, { id: editRecord.key.split("-")[1], name: values.name })
                    .then(res => {
                        message.success('修改成功')
                        setEditModalVisible(false)
                        setEditRecord({})
                        loadData(); // 重新加载数据
                    })
            } else if (editRecord.type === 'city') {
                axiosInstance.post(api.updateCity, { id: editRecord.key.split("-")[1], name: values.name })
                    .then(res => {
                        message.success('修改成功')
                        setEditModalVisible(false)
                        setEditRecord({})
                        loadData(); // 重新加载数据
                    })
            } else if (editRecord.type === 'district') {
                axiosInstance.post(api.updateRegion, { id: editRecord.key.split("-")[1], name: values.name })
                    .then(res => {
                        message.success('修改成功')
                        setEditModalVisible(false)
                        setEditRecord({})
                        loadData(); // 重新加载数据
                    })
            }
        })
    }

    const props: UploadProps = {
        accept: ".xlsx, .xls, .csv",
        name: 'excel',
        multiple: false,
        action: api.addRegionBatch,
        headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} 上传成功.`);
                setImportModalVisible(false);
                loadData();

            } else if (status === 'error') {
                message.error(`${info.file.name} 上传失败.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const columns = [
        {
            title: "名称",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "操作",
            key: "action",
            render: (text, record) => (
                <Space>
                    <Link to="" onClick={() => handleEdit(record)}>修改</Link>
                    <Popconfirm onConfirm={()=>handleDelete(record)} title="警告" okType="danger" okText="删除" description="确定要删除吗" cancelText="取消">
                        <span style={{ color: "red", cursor: "pointer" }}>删除</span>
                    </Popconfirm>
                </Space>
            )
        }
    ]

    return (
        <div className="area-manage">
            <div className='tools'>
                <Space size="large">
                    <Button type='primary' onClick={() => { setProvinceModalVisible(true) }} >添加省</Button>
                    <Button type='primary' onClick={() => { setCityModalVisible(true) }}>添加市</Button>
                    <Button type='primary' onClick={() => { setRegionModalVisible(true) }}>添加区</Button>
                    <Button onClick={()=>{setImportModalVisible(true)}}>批量导入</Button>
                </Space>
            </div>
            <div className='table'>
                <Table pagination={false} columns={columns} dataSource={tableData} loading={tableLoading} />
            </div>
            <Modal
                open={provinceModalVisible}
                okText="确认"
                cancelText="取消"
                onOk={submitProvince}
                onCancel={() => setProvinceModalVisible(false)}
                closable={false}
            >
                <Form form={provinceForm}>
                    <Form.Item name="province" label="省名" rules={[{ required: true, message: "请填写省名" }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                open={cityeModalVisible}
                okText="确认"
                cancelText="取消"
                onOk={submitCity}
                onCancel={() => setCityModalVisible(false)}
                closable={false}
            >
                <Form form={cityForm}>
                    <Form.Item name="province" label="省" rules={[{ required: true, message: "请选择省" }]}>
                        <Select options={provinceOptions}></Select>
                    </Form.Item>
                    <Form.Item name="city" label="市名" rules={[{ required: true, message: "请填写市名" }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                open={regionModalVisible}
                closable={false}
                okText="确认"
                cancelText="取消"
                onOk={submitRegion}
                onCancel={() => setRegionModalVisible(false)}
            >
                <Form form={regionForm}>
                    <Form.Item name="province" label="省" rules={[{ required: true, message: "请选择省" }]}>
                        <Select
                            options={provinceOptions}
                            onChange={handleProvinceChange}
                        ></Select>
                    </Form.Item>
                    <Form.Item name="city" label="市" rules={[{ required: true, message: "请选择市" }]}>
                        <Select options={cityOptions}></Select>
                    </Form.Item>
                    <Form.Item name="region" label="区" rules={[{ required: true, message: "请选择区" }]}>
                        <Input></Input>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="编辑"
                open={editModalVisible}
                closable={false}
                okText="确认"
                cancelText="取消"
                onOk={submitEdit}
                onCancel={() => setEditModalVisible(false)}
            >
                <Form form={editForm}>
                    <Form.Item name="name" rules={[{ required: true, message: "请输入" }]}>
                        <Input></Input>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                open={importModalVisible}
                title="导入"
                footer={null}
                onCancel={() => setImportModalVisible(false)}
                destroyOnClose
            >
                <div className='container'>
                    <div className='upload'>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">点击或者拖拽文件上传</p>
                        </Dragger>
                    </div>
                    <div className='desc'>上传失败后请刷新后重试</div>
                    <a className='download' href='https://mengchong-1322498485.cos.ap-shanghai.myqcloud.com/%E6%A8%A1%E7%89%88%E8%A1%A8%E6%A0%BC/area.csv'>模板下载</a>
                </div>
            </Modal>
        </div>
    )
}

export default AreaManage;